import React, { FC, useContext } from 'react';
import clsx from 'clsx';
import axios from 'axios';

import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Button, Link, RichText, Heading, Types, RadioButton, CheckBox } from '@marriott/mi-ui-library';
import { PageContext, combineAccountFormSubmitURL, scrollToClass } from '../../modules';
import { usecombineAccountsStore } from '../../modules/store/combineAccountsStore';

import { StyledCombineAccountsConsent } from './CombineAccountsConsent.styles';
import { CustomError } from '../CombineAccounts/CombineAccounts.types';
import { CombineAccountsConsentProps } from './CombineAccountsConsent.types';

export const CombineAccountsConsent: FC<CombineAccountsConsentProps> = props => {
  const authorModelData = props?.model;
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const { combineAccountFormFields, setCombineAccountFormFields } = usecombineAccountsStore(state => state); //combineAccount state variables
  const selectionOptionOne = authorModelData?.selectOptionOneText?.replace('{0}', sessionData?.rewardsId ?? '');
  const submitAPICall = async () => {
    return axios.post(
      combineAccountFormSubmitURL,
      {
        //Labels from AEM to pass as payload to BE
        emailSubjectLabel: authorModelData?.emailSubjectLabel ?? '',
        emailBodyLabel: authorModelData?.emailBodyLabel ?? '',
        //FormField
        spgFirstName: combineAccountFormFields?.formFields?.second_firstName,
        spgLastName: combineAccountFormFields?.formFields?.second_lastName,
        spgEmail: combineAccountFormFields?.formFields?.second_email,
        spgCountry: combineAccountFormFields?.formFields?.second_country?.id,
        spgMemberNumber: combineAccountFormFields?.formFields?.second_bonvoyNumber,
        mrMemberNumber: sessionData?.rewardsId ?? '',
        mrFirstName: sessionData?.firstName ?? '',
        mrLastName: sessionData?.lastName ?? '',
        mrTelephone: combineAccountFormFields?.formFields?.phoneNumber,
        //To send the member number for which consent is choosed
        selectedProfile: combineAccountFormFields?.formFields?.accountSelected
          ? sessionData?.rewardsId
          : combineAccountFormFields?.formFields?.second_bonvoyNumber,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Cookie: 'sessionID=' + sessionData?.sessionToken,
        },
      }
    );
  };

  const onSubmit = async () => {
    //Front end validation error check for any empty fields
    if (
      !(
        combineAccountFormFields?.formFields?.second_firstName &&
        combineAccountFormFields?.formFields?.second_lastName &&
        combineAccountFormFields?.formFields?.second_bonvoyNumber &&
        combineAccountFormFields?.formFields?.second_email &&
        combineAccountFormFields?.formFields?.phoneNumber &&
        !combineAccountFormFields?.errorState?.firstNameRegExp &&
        !combineAccountFormFields?.errorState?.lastNameRegExp
      )
    ) {
      setCombineAccountFormFields({
        ...combineAccountFormFields,
        errorState: {
          ...combineAccountFormFields?.errorState,
          isAllEmpty: true,
          matchDetailsError: false,
          firstName: !combineAccountFormFields?.formFields?.second_firstName,
          lastName: !combineAccountFormFields?.formFields?.second_lastName,
          memberNumber: !combineAccountFormFields?.formFields?.second_bonvoyNumber,
          email: !combineAccountFormFields?.formFields?.second_email,
          phone: !combineAccountFormFields?.formFields?.phoneNumber,
          submitted: false,
        },
      });
      return combineAccountFormFields;
    } else {
      try {
        const response = await submitAPICall();
        if (response.status === 200) {
          setCombineAccountFormFields({
            ...combineAccountFormFields,
            errorState: {
              ...combineAccountFormFields?.errorState,
              submitted: true,
            },
          });
        }
      } catch (error) {
        scrollToClass();
        const errorResponse = (error as CustomError)?.response?.data?.errorFields;
        if (errorResponse) {
          setCombineAccountFormFields({
            ...combineAccountFormFields,
            errorState: {
              ...combineAccountFormFields?.errorState,
              isAllEmpty: false,
              matchDetailsError: errorResponse?.matchDetailsError,
              emailSent: errorResponse?.emailSent,
              firstNameRegExp: !!errorResponse?.firstNameBannerError,
              lastNameRegExp: !!errorResponse?.lastNameBannerError,
              firstName: !combineAccountFormFields?.formFields?.second_firstName,
              lastName: !combineAccountFormFields?.formFields?.second_lastName,
              memberNumber: !combineAccountFormFields?.formFields?.second_bonvoyNumber,
              email: !combineAccountFormFields?.formFields?.second_email,
              phone: !combineAccountFormFields?.formFields?.phoneNumber,
              submitted: false,
            },
          });
        }
        return error;
      }
    }
  };

  return (
    <StyledCombineAccountsConsent
      data-testid="combineaccountsconsent"
      data-component-name="o-account-combineaccountsconsent"
    >
      {!combineAccountFormFields?.errorState?.submitted && (
        <div className="container" data-testId="combine-accounts-consent">
          <div className="col-lg-12 col-md-8 offset-md-2 offset-lg-0 col-12 px-0 mb-3 mb-lg-0">
            {/* account selection */}
            <div className="account-selection mb-5">
              <div className="t-subtitle-m mb-5">{authorModelData?.selectionHeader}</div>
              <div>
                <div className="m-radio-button-sm mb-4">
                  <RadioButton
                    data-testid="yes"
                    radiobuttonId="yes"
                    radiobuttonName="accountSelection"
                    radiobuttonLabel={selectionOptionOne ?? ''}
                    checked={combineAccountFormFields?.formFields?.accountSelected === true}
                    onChange={() => {
                      setCombineAccountFormFields({
                        ...combineAccountFormFields,
                        formFields: {
                          ...combineAccountFormFields?.formFields,
                          accountSelected: true,
                        },
                      });
                    }}
                    value="true"
                    customClickClass={''}
                    setTabIndex={0}
                    custom_click_track_value={''}
                    labelClassName="t-font-m mb-0"
                  />
                </div>
                <div className="m-radio-button-sm">
                  <RadioButton
                    data-testid="no"
                    radiobuttonId="no"
                    radiobuttonName="accountSelection"
                    radiobuttonLabel={authorModelData?.selectOptionTwoText ?? ''}
                    checked={combineAccountFormFields?.formFields?.accountSelected === false}
                    onChange={() => {
                      setCombineAccountFormFields({
                        ...combineAccountFormFields,
                        formFields: {
                          ...combineAccountFormFields?.formFields,
                          accountSelected: false,
                        },
                      });
                    }}
                    value="false"
                    customClickClass={''}
                    setTabIndex={0}
                    custom_click_track_value={''}
                    labelClassName="t-font-m"
                  />
                </div>
              </div>
            </div>
            <div className="after-submitting">
              <Heading
                variation={Types.headingType.subtitle}
                fontSize={Types.size.extraLarge}
                titleText={authorModelData?.afterSubmittingSubHeader}
                customClass="after-submitting--header t-subtitle-xl mb-4"
              />
              <div className="d-lg-flex">
                <div className="col-lg-6 px-0 px-md-1">
                  <Heading
                    variation={Types.headingType.subtitle}
                    fontSize={Types.size.medium}
                    titleText={authorModelData?.userWillHaveDetailsHeader}
                    customClass="mb-3"
                  />
                  <RichText
                    text={authorModelData?.userWillHaveDetails ?? ''}
                    componentId={'user-will-have'}
                    customClass="pl-0 t-font-m"
                  />
                </div>
                <div className="col-lg-6 px-0 px-md-1 mt-lg-0 mt-md-3 mt-4">
                  <Heading
                    variation={Types.headingType.subtitle}
                    fontSize={Types.size.medium}
                    titleText={authorModelData?.whatWillBeCombinedDetailsHeader}
                    customClass="mb-3"
                  />
                  <RichText
                    text={authorModelData?.whatWillBeCombinedDetails ?? ''}
                    componentId={'combine-message'}
                    customClass="pl-0 t-font-m"
                  />
                </div>
              </div>
            </div>
            <div className="form-bar my-5"></div>
            <div className="consent-checkbox mb-5">
              <CheckBox
                key={'checkbox'}
                data-testid="consent"
                checkboxName='"accountSelection"'
                checkboxId="consent"
                className="notification-option"
                checked={combineAccountFormFields?.formFields?.consentChecked === true}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCombineAccountFormFields({
                    ...combineAccountFormFields,
                    formFields: {
                      ...combineAccountFormFields?.formFields,
                      consentChecked: event.target.checked,
                    },
                  });
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault();
                    event.stopPropagation();
                    setCombineAccountFormFields({
                      ...combineAccountFormFields,
                      formFields: {
                        ...combineAccountFormFields?.formFields,
                        consentChecked: !combineAccountFormFields?.formFields?.consentChecked,
                      },
                    });
                  }
                }}
              >
                <span className="t-subtitle-m">{authorModelData?.consentText}</span>
              </CheckBox>
            </div>
            <div className="consent-button d-md-flex">
              <div>
                <Button
                  className={clsx(
                    't-font-m py-2 m-button-primary btn',
                    !combineAccountFormFields?.formFields?.consentChecked && 'disabled'
                  )}
                  callback={onSubmit}
                  testId="consent-button"
                >
                  {authorModelData?.submitButtonLabel}
                </Button>
              </div>
              <div className="my-md-1 mt-5 d-md-block d-flex justify-content-center mx-5">
                <Link
                  text={authorModelData?.cancelButtonLabel}
                  linkClassName="t-font-s m-link py-0 mx-0"
                  ariaLabel={authorModelData?.cancelButtonLabel}
                  linkHref={authorModelData?.cancelButtonLink ?? ''}
                  target={authorModelData?.cancelLinkNewTab ? '_blank' : '_self'}
                ></Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledCombineAccountsConsent>
  );
};

export const CombineAccountsConsentConfig = {
  emptyLabel: 'combineAccountsConsent',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/combineaccountsconsent`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CombineAccountsConsentEditable = (props: any) => (
  <EditableComponent config={CombineAccountsConsentConfig} {...props}>
    <CombineAccountsConsent {...props} />
  </EditableComponent>
);
