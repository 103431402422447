// Styles for CreateAccountForm go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledCreateAccountForm = styled.div`
  .activate_account--countrydropdown {
    width: 100%;
    height: ${toRem(44.67)};
    border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
    border-color: ${baseVariables.color['neutral40']} !important;
    padding-top: ${toRem(12)} !important;
    padding-bottom: ${toRem(12)} !important;
    .icon-dropdown-down,
    .icon-dropdown-up {
      position: absolute;
      right: ${toRem(8)};
    }
  }

  .dropdown {
    cursor: pointer;
  }
  .dropdown-value {
    font-size: ${toRem(16)};
  }

  .dropdown__container {
    color: ${baseVariables.color['neutral40']} !important;
    z-index: 2;
  }
  .country-label {
    z-index: 1;
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: auto;
      max-height: ${toRem(224)};
    }
  }

  .dropdown__container {
    z-index: 99;
  }
  .password-checks {
    margin-bottom: ${toRem(24)} !important;
  }
  .pointer-cursor {
    cursor: pointer;
    text-decoration: underline;
  }
  .disableField * {
    color: ${baseVariables.color['neutral30']} !important;
    border-color: ${baseVariables.color['neutral30']} !important;
  }
  .m-input-field {
    &.is-disabled {
      label {
        z-index: 1;
      }
    }
    caret-color: inherit; // This is to override transparent caret color
  }
  .error-label,
  .error-label * {
    font-size: ${baseVariables.font.fontXs};
    color: ${baseVariables.color.alert50} !important;
  }

  .create-account__link {
    @media ${baseVariables.mediaQuery.md} {
      align-items: center;
    }
  }

  .link-underline {
    font-size: ${toRem(16)};
    display: inline-block;
    width: fit-content;
  }
  .m-badge-inline-neutral {
    padding: 0;
    background-color: ${baseVariables.color.merch50} !important;
    color: ${baseVariables.color.neutral40} !important;
    text-align: unset !important;
  }

  .dropdown-field {
    label {
      left: ${toRem(16)}; // to override left spacing when ellipsis gets added
      @media ${baseVariables.mediaQuery.lg} {
        left: ${toRem(20)}; // to override left spacing when ellipsis gets added
      }
    }
  }

  .account-page-error-msg p {
    margin-bottom: 0 !important;
  }
  .create-password-eaa {
    .password-checks {
      margin-bottom: ${toRem(16)} !important;
    }
  }

  .resend-code {
    p {
      margin-bottom: 0 !important;
    }
  }
  .is-disabled {
    .password-checks {
      &__description {
        color: ${baseVariables.color.neutral30} !important;
      }
    }
  }
  .create-account-password {
    .password-checks {
      &__errorlabel {
        font-weight: 400 !important;
      }
    }
  }
  .country-code-dropdown {
    display: flex;
    align-items: center;
  }
`;
