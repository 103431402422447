import React, { ReactNode, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { CalendarProps, CalendarTooltipPosType, ControllerType } from './Calendar.types';

import { getDurationBetweenDates, getCurrentDateObject } from '../../utils/DateUtils';
import { DayModifiers, Modifiers, RangeModifier } from 'react-day-picker';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';

export const VARIATION_VERTICAL = 'VERTICAL';
export const VARIATION_DESKTOP = 'DESKTOP';
export const VARIATION_TABLET = 'TABLET';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const KO_KR = 'ko-KR';
export const CN_YEAR_LABEL = '年';
export const JA_JP = 'ja-JP';
export const IS_LOCALE_THAI = 'th-TH';

export const useCalendar = (props: CalendarProps): ControllerType => {
  const {
    singleDatePicker,
    startFromToday = false,
    className,
    variation,
    startDate,
    endDate,
    focusedInput,
    setDateHandler,
    setFocusedInputHandler,
    nightsLabel,
    nightLabel,
    maxAllowedDays,
    weekdays,
    months,
    customDatesFlag,
    weekdaysLong,
    monthsShort,
    weekdaysShort,
    firstDayOfWeek,
    customFromDate,
    customToDate,
    disabledDays = [],
    /*
    ignore variation for date selection on mobile, user can focused the input which date user wants to select
     maintain the desktop variation in terms of date selection
    */
    isThereTwoDateInputBox = false,
    numberOfMonths,
    customRenderDayCell,
    showOutsideDays,
    fixedWeeks,
    onDayClickEvent,
    defaultMonth,
    onSinlgeClickDateRangeSelect,
    displayFooterSection = false,
    footerItems,
    footerBackGround,
    isPastDateSelectionDisabled,
    enableFromPastDate,
    scrollIntoViewBlock = 'start',
    locale,
    yearLabel = '',
    pagedNavigation,
  } = props;

  /**
   * this file is a wrapper for calendar
   * it will modify or update some props
   * it will return some extra props based on user input
   * @param props
   * accept calendar props as input
   * @returns
   *  extra props to calendar
   * handle all the logic for dates
   * updates starting range of input  and end range of date
   * wrap some event for calendar
   * like mouse hover and click
   * wrap mouse enter and other mouse interaction event
   */
  const SCROLL_DATE_MARKER_DATA_ATTRIBUTE = 'data-scroll-date-marker';
  const SCROLL_DATE_MARKER_DATE_FORMAT = 'MMMM YYYY';

  const ALLOWED_DAY_THRESHOLD = maxAllowedDays;

  const [rectHoveredDay, setRectHoveredDay] = React.useState<CalendarTooltipPosType>();
  const [hoverDate, setHoverDate] = React.useState<Date | undefined>(undefined);
  const dateRef = useRef<HTMLDivElement>(null);

  // set selectedDateType to identify selected date type
  type SelectedDateType = 'startDate' | 'endDate' | null;
  const [selectedDateType, setSelectedDateType] = React.useState<SelectedDateType>(START_DATE);

  // set selectedDaysRange & selectedDaysModifier
  const today = getCurrentDateObject().toDate();
  const fromDate: Date | undefined = startDate?.toDate() ?? undefined;
  const toDate: Date | undefined = endDate?.toDate() ?? undefined;
  const dateRange: RangeModifier | undefined =
    startDate !== undefined && endDate !== undefined ? { from: fromDate!, to: toDate! } : undefined;
  // disable prev and next navigation arrow for prev months and after 1 year days if no customFromDate and customToDate
  //disable prev and next navigation arrow for custom dates
  const fromMonth = !isPastDateSelectionDisabled && enableFromPastDate ? enableFromPastDate : customFromDate ?? today;
  const toMonth = customToDate ?? moment().add(ALLOWED_DAY_THRESHOLD, 'days').toDate();

  // selected date range used to set date range
  const [selectedDaysRange, setSelectedDaysRange] = useState<[Date | undefined, RangeModifier | undefined]>([
    fromDate,
    dateRange,
  ]);

  // selectedDaysModifier used for date styling

  const [selectedDaysModifier, setselectedDaysModifier] = useState<Partial<Modifiers> | undefined>({
    start: fromDate,
    end: toDate,
    select: fromDate,
    hoverRange: undefined,
    fromHover: undefined,
    endHover: undefined,
    fromFocus: undefined,
    toFocus: undefined,
  });
  const displayMonth =
    startDate && variation !== VARIATION_VERTICAL
      ? new Date(moment(startDate).year(), moment(startDate).month())
      : defaultMonth;
  const [initialMonth] = useState(displayMonth);
  const [disabledDay, setDisabledDay] = useState([{ before: today, after: toMonth }, ...disabledDays]);
  const startDateRef = React.useRef(startDate);
  const endDateRef = React.useRef(endDate);
  const selectedDaysModifierRef = React.useRef(selectedDaysModifier);
  /**
   * update mouse enter event to day cells
   */
  const updateMouseEnterEvent = (attach: boolean): void => {
    // attach mouse enter event on day cell
    const daysEl = document.querySelectorAll(
      '.DayPicker-wrapper-react-el .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)'
    );
    if (attach) {
      daysEl.forEach(day => {
        day.addEventListener('mouseenter', onDayCellMouseEnter);
      });
    } else {
      daysEl.forEach(day => {
        day.removeEventListener('mouseenter', onDayCellMouseEnter);
      });
    }
  };

  useEffect(() => {
    selectedDaysModifierRef.current = selectedDaysModifier;
  }, [selectedDaysModifier]);

  //To override and setDisabled days for customFrom and customToDates
  useEffect(() => {
    if (customDatesFlag) {
      setDisabledDay([
        { before: customFromDate ? customFromDate : today, after: customToDate ? customToDate : toMonth },
      ]);
    }
  }, [customDatesFlag]);

  useEffect(() => {
    //To bring scroll to start date month when in mobile view.
    if (variation === VARIATION_VERTICAL && startDate) {
      const captions = dateRef.current?.querySelectorAll('.DayPicker-Caption');
      const captionInners = dateRef.current?.querySelectorAll('.DayPicker-Caption div');

      if (!captions?.length || !captionInners?.length) {
        return;
      }

      const startFormattedDate = startDate
        ?.locale(locale || '')
        ?.format(SCROLL_DATE_MARKER_DATE_FORMAT)
        ?.toString()
        ?.toLowerCase();

      for (let i = 0; i < captions?.length; i++) {
        const markerFormattedDate = captionInners[i]?.getAttribute(SCROLL_DATE_MARKER_DATA_ATTRIBUTE);
        if (startFormattedDate === markerFormattedDate) {
          captions[i].scrollIntoView?.({ block: scrollIntoViewBlock });
          break;
        }
      }
    }

    updateMouseEnterEvent(true);

    return (): void => {
      updateMouseEnterEvent(false);
    };
  }, []);

  // disable todays date when focus is on end date
  useEffect(() => {
    if (focusedInput === 'customDate') {
      return;
    } else if (variation !== VARIATION_VERTICAL && !customDatesFlag) {
      setDisabledDay([{ before: today, after: toMonth }, ...disabledDays]);
    }

    if (singleDatePicker && !startFromToday) {
      const date = new Date().setDate(new Date().getDate() - 1);
      setDisabledDay([{ before: new Date(date), after: toMonth }, ...disabledDays]);
    }
  }, [focusedInput]);

  // To hide the tooltip once startdate and enddate are selected + add/remove class when start/end date selected/removed respectively
  useEffect(() => {
    if (startDate && endDate) {
      setHoverDate(undefined);
      dateRef.current?.classList.add('startdate-enddate-selected');
    } else {
      dateRef.current?.classList.remove('startdate-enddate-selected');
    }

    if (!endDate) {
      updateMouseEnterEvent(true);
    }

    if (!startDate && !endDate) {
      setSelectedDaysRange([undefined, undefined]);
      setselectedDaysModifier({
        start: undefined,
        end: undefined,
        select: undefined,
        hoverRange: undefined,
        fromHover: undefined,
        endHover: undefined,
        fromFocus: undefined,
        toFocus: undefined,
      });
    }
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    if (singleDatePicker && focusedInput === START_DATE) {
      setSelectedDaysRange([today, undefined]);
      // when focus is on selected date
    }
  }, [startDate, endDate]);

  // To hide the tooltip once mouse goes out of calendar
  const onDayMouseLeave = (modifiers: DayModifiers): void => {
    if (modifiers['disabled']) {
      return;
    }
    setHoverDate(undefined);
    setselectedDaysModifier({
      ...selectedDaysModifier,
      hoverRange: undefined,
      fromHover: undefined,
      endHover: undefined,
      fromFocus: undefined,
      toFocus: undefined,
    });
  };

  /**
   *  returns true if tooltip should display
   */
  const shouldDisplayToolTip = (hoveredDate: Date): boolean | null => {
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    const momentHoverDate = moment(hoveredDate);
    return (
      /* if end date is empty and active date field is not on start date, tool tip should display
       * if hover date is greater than start date
       */
      (startDate && !endDate && momentStartDate < momentHoverDate && focusedInput !== START_DATE) ||
      /* if start date is empty and active date field is not on end date,
       * tool tip should display if hover date is less than hover date
       */
      (!startDate && endDate && momentEndDate > momentHoverDate && focusedInput !== END_DATE) ||
      /* if start date and end date are selected, active date field is start date & hover date
       * is less than end date then tool tip should display
       * if start date and end date are selected, active date field is end date & hover date
       * is greater than start date then tool tip should display
       */
      (startDate &&
        endDate &&
        ((momentEndDate > momentHoverDate && focusedInput === START_DATE) ||
          (momentStartDate < momentHoverDate && focusedInput === END_DATE)))
    );
  };
  /**
   * calculates number of nights on hovered state
   */
  const numberOfNightsValue = (): React.ReactNode => {
    if (hoverDate && rectHoveredDay) {
      const curr = hoverDate;
      let d = startDate ?? endDate;
      if (d && shouldDisplayToolTip(hoverDate)) {
        if (startDate && endDate) {
          d = focusedInput === START_DATE ? endDate : startDate;
        }

        const start = d.format('YYYY-MM-DD');
        const currentDate = moment(curr).format('YYYY-MM-DD');
        // Using Math.abs to get positive numbers
        const calculatedValue = Math.abs(getDurationBetweenDates(start, currentDate));
        const style: React.CSSProperties = {
          position: 'absolute',
          top: `${rectHoveredDay.top - 30}px`,
          left: `${rectHoveredDay.left - 5}px`,
          zIndex: 20,
        };
        return (
          <>
            {calculatedValue > 0 && (
              <span
                style={style}
                className="DayPicker-tooltip-value t-font-inverse-xs py-1 px-2 "
              >{`${calculatedValue} ${calculatedValue > 1 ? nightsLabel : nightLabel}`}</span>
            )}
          </>
        );
      }
    }
    return null;
  };

  /**
   * calculates number of vertical months for mobile view based on days threshold
   */
  const getVerticalMonths = (): number => {
    const startMonths = Number(moment().format('M'));
    const startYearsMonths = Number(moment().format('YYYY')) * 12;
    const start = startMonths + startYearsMonths;
    const lastDate = moment().add(ALLOWED_DAY_THRESHOLD, 'days');
    const lastMonths = Number(lastDate.format('M'));
    const lastYearsMonths = Number(lastDate.format('YYYY')) * 12;
    const last = lastMonths + lastYearsMonths;
    return last - start + 1;
  };

  /**
   *
   * @param e
   */
  const onDayCellMouseEnter = (e: Event): void => {
    const el = e.target as HTMLElement;
    if (el) {
      const day = (el.querySelector('.DayPicker-Day-value') as HTMLSpanElement)?.innerText;
      const month = (el.querySelector('.DayPicker-Day-month-value') as HTMLInputElement)?.value;
      const year = (el.querySelector('.DayPicker-Day-year-value') as HTMLInputElement)?.value;
      const date = new Date(Number(year), Number(month), Number(day));
      if (date) {
        onDayMouseEnter(date, e);
      }
    }
  };

  /**
   * on mouse enter on date set hover date and rect hover day
   * @param day
   * @param event
   */
  const onDayMouseEnter = (day: Date, event: React.MouseEvent<HTMLDivElement> | Event): void => {
    if (selectedDaysModifierRef.current?.['disabled']) {
      return;
    }
    const start = startDateRef?.current;
    const end = endDateRef?.current;
    const target = event.target as HTMLElement;
    setRectHoveredDay({ top: target.offsetTop, left: target.offsetLeft });
    setHoverDate(day);
    if (!start || !end) {
      if (start && day > start.toDate()) {
        setselectedDaysModifier({
          ...selectedDaysModifierRef.current,
          hoverRange: { from: start.toDate(), to: day },
          endHover: day,
          fromFocus: start.toDate(),
        });
      } else if (end && day < end.toDate()) {
        setselectedDaysModifier({
          ...selectedDaysModifierRef.current,
          hoverRange: { from: day, to: end.toDate() },
          fromHover: day,
          endFocus: end.toDate(),
        });
      }
    }
  };
  /**
   * set date modifer using date only & update input field focus
   * @param day
   * @param inutFocus
   * @param selectedDateType
   */
  const setSingleDateOnly = (day: Date, inutFocus: SelectedDateType, selectedDateType: SelectedDateType): void => {
    setSelectedDaysRange([day, undefined]);
    setselectedDaysModifier({ ...selectedDaysModifier, select: day, start: undefined, end: undefined });
    setFocusedInputHandler(inutFocus);
    setSelectedDateType(selectedDateType);
  };
  /**
   * set from date
   * @param day
   */
  const setFromDate = (day: Date): void => {
    setSingleDateOnly(day, END_DATE, START_DATE);
    setDateHandler(moment(day), null);
    startDateRef.current = moment(day);
    endDateRef.current = null;
  };
  /**
   * set end date date
   * @param day
   */
  const setEndDate = (day: Date): void => {
    setSingleDateOnly(day, START_DATE, END_DATE);
    setDateHandler(null, moment(day));
    startDateRef.current = null;
    endDateRef.current = moment(day);
  };

  const resetCalendarDates = (): void => {
    setSelectedDaysRange([undefined, undefined]);
    setselectedDaysModifier({
      start: undefined,
      end: undefined,
      select: undefined,
      hoverRange: undefined,
      fromHover: undefined,
      endHover: undefined,
      fromFocus: undefined,
      toFocus: undefined,
    });
    return;
  };

  const setEndFromDatesForcefully = (fromDate: Date | null, endDate: Date | null): void => {
    /**
     * reset selected dates if required
     *
     */

    if (endDate) {
      setSelectedDaysRange([undefined, { from: fromDate, to: endDate }]);
      setselectedDaysModifier({ ...selectedDaysModifier, start: fromDate || today, end: endDate, select: undefined });
      startDateRef.current = moment(fromDate);
      endDateRef.current = moment(endDate);
    } else if (fromDate) {
      setSingleDateOnly(fromDate, END_DATE, START_DATE);
      startDateRef.current = moment(fromDate);
      endDateRef.current = null;
    }
  };
  /**
   * set date range using from and to date
   * @param fromDate
   * @param toDate
   */
  const setFromEndDate = (fromDate: Date | undefined, toDate: Date | undefined): void => {
    setSelectedDaysRange([undefined, { from: fromDate, to: toDate }]);
    setselectedDaysModifier({ ...selectedDaysModifier, start: fromDate, end: toDate, select: undefined });
    setDateHandler(moment(fromDate), moment(toDate));
    startDateRef.current = moment(fromDate);
    endDateRef.current = moment(toDate);
  };

  const onDateSelect = (day: Date, modifiers: DayModifiers): void => {
    if (modifiers['disabled']) {
      return;
    }
    if (focusedInput === START_DATE) {
      setSelectedDaysRange([day, undefined]);
      // when focus is on selected date
    }
    setDateHandler(moment(day), null);
  };

  /**
   * on day click handler event.set from and end date.
   * @param day
   * @param modifiers
   */
  const onDayClickHandler = (day: Date, modifiers: DayModifiers): void => {
    if (modifiers['disabled']) {
      return;
    }
    const momentSelectedDay = moment(day);

    // A -when both dates are selected not selected set selected date
    if (!selectedDaysRange[0] && !selectedDaysRange[1]) {
      // when focus is on start set, set selected date as from date
      if (focusedInput === START_DATE) {
        setFromDate(day);
        // when focus is on end date, set selected date as to date
      } else if (focusedInput === END_DATE) {
        setEndDate(day);
      }
    }
    // B-  when both dates are already selected, update both start and end date
    else if (selectedDaysRange[1] !== undefined) {
      const dateRange = selectedDaysRange[1];
      const fromDate = dateRange.from ?? undefined;
      const toDate = dateRange.to ?? undefined;
      const momentFromDate = moment(fromDate);
      const momentToDate = moment(toDate);
      /*
        1. when focus is on start date and selected date is greater than end date,
        clear end date and set selected date as start date
        2. when focus is on end date and selected date is less than from date,
        clear end date and set selected date as start date
        3. for mobile view when check in and checkout dates are already selected,
          set newly selected date as check in date and clear check out date
      */
      if (
        (focusedInput === START_DATE && momentSelectedDay > momentToDate) ||
        (focusedInput === END_DATE && momentSelectedDay < momentFromDate) ||
        (variation === VARIATION_VERTICAL && !isThereTwoDateInputBox)
      ) {
        setFromDate(day);
      } else if (
        /*
        When focus in on start date and user selects a start date
        which is less than already selected start date , it resets end date
        */
        focusedInput === START_DATE &&
        momentSelectedDay <= momentToDate &&
        momentSelectedDay <= momentFromDate
      ) {
        setFromDate(day);
      } /*
        1. when focus is on start date and selected date is less than end date, then set both dates
        2. when focus is on end date and selected date is greater than from date, then set both dates
      */ else if (focusedInput === START_DATE && momentSelectedDay <= momentToDate) {
        setFromEndDate(day, toDate);
      } else if (focusedInput === END_DATE && momentSelectedDay >= momentFromDate) {
        setFromEndDate(fromDate, day);
      }
      // C - when single date is already selcted.
    } else if (selectedDaysRange[0] !== undefined) {
      const preSelectedDate = selectedDaysRange[0];
      const momentPreSelectedDate = moment(preSelectedDate);
      /*
       1. when focus is on start date and start date is already selected update start date only
       2. when focus is on start date and end date is already selected & selected date is greater than end date then
          set selected date as from date and clear end date
       3. when focus is on end date & from date is already selected & selected day is less than from date then
          set selected date as from date and clear end date
      */
      if (
        (focusedInput === START_DATE && selectedDateType === START_DATE) ||
        (focusedInput === START_DATE && selectedDateType === END_DATE && momentSelectedDay > momentPreSelectedDate) ||
        (focusedInput === END_DATE && selectedDateType === START_DATE && momentSelectedDay < momentPreSelectedDate)
      ) {
        setFromDate(day);
        /*
         when focus is on start date and end date is already selected & end date is greater than selected date then set both dates
        */
      } else if (
        focusedInput === START_DATE &&
        selectedDateType === END_DATE &&
        momentSelectedDay < momentPreSelectedDate
      ) {
        setFromEndDate(day, preSelectedDate);
      }
      // when focus is on end date & from date is already selected & selected date is greater than already selected from date then set both dates
      else if (
        focusedInput === END_DATE &&
        selectedDateType === START_DATE &&
        momentSelectedDay > momentPreSelectedDate
      ) {
        setFromEndDate(preSelectedDate, day);
      }
      // when focus is on end date and end date is already selected then udpate end date
      else if (focusedInput === END_DATE && selectedDateType === END_DATE) {
        setEndDate(day);
      }
    }
    /**
     * 1. if ondayclick is passed
     * 2. call this function whenever user interact with day by clicking on it
     * 3. for reseting the focus function if user double click on day
     */
    onDayClickEvent && onDayClickEvent(day, setFromDate);
    if (onSinlgeClickDateRangeSelect) {
      onSinlgeClickDateRangeSelect(day, setEndFromDatesForcefully);
    }
  };
  /**
   *
   * @param day as date object
   */
  const renderDay = (day: Date): ReactNode => {
    return (
      <div className="DayPicker-Day-Cell d-flex align-items-center justify-content-center">
        <span className="DayPicker-Day-value t-font-s d-flex align-items-center justify-content-center">
          {day?.getDate()}
        </span>
        <input className="DayPicker-Day-month-value" type="hidden" value={day?.getMonth()} />
        <input className="DayPicker-Day-year-value" type="hidden" value={day?.getFullYear()} />
      </div>
    );
  };

  /**
   *
   * @param day
   */
  const captionElement = (day: { date: Date }): JSX.Element => {
    const monthVal = day.date.getMonth();
    const localeUpdate = locale?.replace('_', '-');
    const yearVal = locale === IS_LOCALE_THAI ? day.date.getFullYear() + 543 : day.date.getFullYear();
    let captionElText = `${months[monthVal]} ${yearVal}`;
    if (localeUpdate === KO_KR || localeUpdate === JA_JP) {
      captionElText = `${yearVal}${yearLabel}${localeUpdate === JA_JP ? '' : ' '}${months[monthVal]}`;
    } else if (isCNLocale(locale)) {
      captionElText = `${yearVal}${CN_YEAR_LABEL} ${months[monthVal]}`;
    }

    const markerFormattedDate = moment(day.date)?.format(SCROLL_DATE_MARKER_DATE_FORMAT)?.toString()?.toLowerCase();

    return (
      <div className="DayPicker-Caption">
        <div {...{ [SCROLL_DATE_MARKER_DATA_ATTRIBUTE]: markerFormattedDate }}>{captionElText}</div>
      </div>
    );
  };

  return {
    singleDatePicker,
    variation,
    className,
    dateRef,
    startDate,
    endDate,
    setDateHandler,
    focusedInput,
    setFocusedInputHandler,
    getVerticalMonths,
    numberOfNightsValue,
    onDayMouseEnter,
    onDayClickHandler,
    onDateSelect,
    selectedDaysModifier,
    selectedDaysRange,
    hoverDate,
    onDayMouseLeave,
    fromMonth,
    toMonth,
    renderDay,
    shortWeekdays: weekdays,
    monthsValue: months,
    weekdaysLongName: weekdaysLong,
    defaultMonth: initialMonth,
    disabledDays: disabledDay,
    updateMouseEnterEvent,
    weeksdaysShort: weekdaysShort,
    monthsShortValue: monthsShort,
    firstDayOfWeek,
    numberOfMonths,
    customRenderDayCell,
    setEndFromDatesForcefully,
    resetCalendarDates,
    showOutsideDays: showOutsideDays ?? false,
    displayFooterSection,
    footerItems,
    fixedWeeks,
    footerBackGround,
    isPastDateSelectionDisabled,
    captionElement,
    pagedNavigation,
  };
};
