import { toRem } from '../../styles';
import styled from 'styled-components';
import { baseVariables } from '../../styles';

export const StyledCardCarousel = styled.div<{ variation: string }>`
  @media ${baseVariables.mediaQuery.xl} {
    display: ${props => (props.variation === 'combo' ? 'flex' : 'block')};
  }
  .aem-container:first-child {
    margin-left: 0;
  }
  .carousal_header {
    display: flex;
    flex-direction: ${props => (props.variation === 'combo' ? 'column' : ' row')};
    justify-content: ${props => (props.variation === 'combo' ? 'center' : ' space-between')};
    align-items: ${props => (props.variation === 'combo' ? 'flex-start' : ' unset')};
    width: auto;
    @media ${baseVariables.mediaQuery.md} {
      width: ${props => (props.variation === 'combo' ? 'auto' : 'auto')};
    }

    .carousal_header--cta {
      display: flex;
      align-items: ${props => (props.variation === 'combo' ? 'unset' : ' flex-end')};
      margin-right: ${props => (props.variation === 'combo' ? 0 : toRem(4))};
      text-wrap: nowrap;
    }
  }
  .glide {
    &__track {
      padding-top: ${toRem(24)};
      .glide__slides_authoring {
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
    }
    .glide__slides > .cq-Editable-dom > .cq-Editable-dom--container {
      display: contents;
    }
    &__track__pt3 {
      overflow: hidden;
      padding-top: ${toRem(8)};
      .glide__slides_authoring {
        width: 100% !important;
        flex-wrap: wrap;
      }
      .glide__slides {
        .aem-container {
          padding: 0;
        }
      }
    }
    .center-align {
      display: flex;
      justify-content: center;
      align-items: center;
      .carouselControlType1 {
        .right-arrow {
          right: -1rem;
          @media ${baseVariables.mediaQuery.sm} {
            right: -1.25rem;
          }
        }
        .left-arrow {
          left: -1rem;
          @media ${baseVariables.mediaQuery.sm} {
            left: -1.25rem;
          }
        }
        .left-arrow-rtl {
          left: auto;
          right: -1rem;
        }
        .right-arrow-rtl {
          right: auto;
          left: -1rem;
        }
      }
    }
  }
  .glide--swipeable {
    cursor: default;
  }
`;
