import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledForgotPasswordEmailSent = styled.div`
  .spacing-top-bottom {
    margin-top: ${toRem(56)};
    margin-bottom: ${toRem(72)};
    @media ${baseVariables.mediaQuery.md} {
      margin-top: ${toRem(80)};
      margin-bottom: ${toRem(136)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      margin-bottom: ${toRem(160)};
    }
  }
`;

export const StyledCustomMessage = styled.div`
  .error-message {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      width: ${toRem(348)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      width: ${toRem(558)};
    }
  }
  .custom-message-wrapper {
    margin-top: ${toRem(48)};
  }
  .custom-error-wrapper {
    margin-bottom: ${toRem(64)};
  }
`;
