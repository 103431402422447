import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
const { xl, md } = { ...baseVariables.mediaQuery };

export const StyledPromotionBanner = styled.div`
  .pb-item {
    position: relative;
    overflow: hidden;
    & .pb-item {
      &__skeleton {
        &__img {
          width: 100%;
          height: ${toRem(364)};
          @media ${md} {
            height: ${toRem(256)};
          }
          @media ${xl} {
            height: ${toRem(480)};
          }
        }
      }
    }
  }
`;
