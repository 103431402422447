// Styles for PasswordInput go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledPasswordInput = styled.div`
  .m-input-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      &::-ms-reveal {
        display: none;
      }
    }
    &.is-error {
      &:focus-within {
        label {
          color: ${baseVariables.color.alert50};
        }
      }
      input:focus {
        box-shadow: none;
        ouline: 0;
      }
    }
    &.disabled {
      label {
        color: ${baseVariables.color.alert20};
      }
      input {
        border-color: ${baseVariables.color.alert20};
        outline: 0;
        color: ${baseVariables.color.neutral50} !important;
        &:focus {
          outline: 0;
          border-color: ${baseVariables.color.alert20};
          box-shadow: none;
        }
      }
    }
  }
  .password-valid-check {
    position: absolute;
    left: initial;
    right: ${toRem(34)};
    top: ${toRem(10)};
  }
  .green-check {
    color: ${baseVariables.color.alert30} !important;
  }
  .red-clear {
    color: ${baseVariables.color.alert50} !important;
  }
  .fade-icon {
    color: ${baseVariables.color.neutral30} !important;
  }
  .m-input-field {
    &.is-disabled {
      label {
        z-index: 1;
      }
    }
    caret-color: inherit; // This is to override transparent caret color
  }
`;
