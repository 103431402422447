import React, { FC } from 'react';
import clsx from 'clsx';

import { Heading, Types, RichText, Icon, Button } from '@marriott/mi-ui-library';

import { IconEnrollBlockProps } from '../SignInContentBlock.types';

// Use named rather than default exports.
export const IconEnrollBlock: FC<IconEnrollBlockProps> = props => {
  return (
    <div className="text-content-container pl-2 pl-lg-0">
      <Heading
        element={Types.tags.h2}
        titleText={
          props?.rememberedUser
            ? props?.rememberedUserHeader?.replace('{0}', props?.rememberedUser || '')
            : props?.contentBlockHeader
        }
        customClass="mb-0"
        variation={Types.headingType.subtitle}
        fontSize={Types.size.extraLarge}
      />
      <RichText
        text={props?.contentBlockDescription ?? ''}
        componentId={'content-block-description'}
        customClass="content-block-description t-font-m point-list mt-2"
      />
      <div className={props?.isOverLay ? 'icon-box-container-overlay' : 'icon-box-container'}>
        <div className="horizontal-bar py-4">
          <hr />
        </div>
        <div className="icon-container">
          {props?.iconDetails?.map((iconDetail, index) => (
            <div
              className={clsx(
                'd-flex align-items-center justify-content-center text-center flex-column icon-container__detail'
              )}
              key={index}
            >
              <Icon iconClass={`content-icon icon-decorative ${iconDetail?.icon}`}></Icon>
              <div className="t-overline-normal mt-3">{iconDetail?.title}</div>
            </div>
          ))}
        </div>
      </div>
      {props?.joinNowLabel && (
        <div className="mt-4 mt-md-5">
          <Button
            isLink={true}
            buttonCopy={props?.joinNowLabel}
            target={props?.isOverLay ? '_parent' : '_self'}
            href={props?.joinNowCtaPath}
            custom_click_track_value={props?.clickTrackValue}
            className={clsx(
              'm-button-m m-button-secondary text-center width-mobile-100 d-flex-sm justify-content-center align-items-center px-4 px-md-3',
              props?.isOverLay ? 'join-now-btn-overlay' : 'join-now-btn'
            )}
          />
        </div>
      )}
    </div>
  );
};
