import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledForceChangePassword = styled.div`
  .m-input-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      &::-ms-reveal {
        display: none;
      }
    }
  }
  .activate_account--countrydropdown {
    width: 100%;
    height: ${toRem(44.67)};
    border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
    border-color: ${baseVariables.color['neutral40']} !important;
    padding-top: ${toRem(12)} !important;
    padding-bottom: ${toRem(12)} !important;
    .icon-dropdown-down,
    .icon-dropdown-up {
      position: absolute;
      right: ${toRem(8)};
    }
    .dropdown-value {
      color: ${baseVariables.color['neutral40']} !important;
      font-size: ${toRem(16)};
    }
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: ${toRem(224)};
    }
  }
  .country-label {
    z-index: 1;
  }
  .space-bottom {
    margin-bottom: ${toRem(88)};
  }
  .custom-btn {
    width: 100%;
    @media ${baseVariables.mediaQuery['sm']} {
      width: auto;
      min-width: ${toRem(128)}; // UX team has asked to set the width of button as per VD
    }
  }
  .submit_btn {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      width: auto;
    }
  }
  .error-label,
  .error-label * {
    font-size: ${baseVariables.font.fontXs};
    color: ${baseVariables.color.alert50} !important;
    p {
      margin: 0;
      padding: 0;
    }
  }
  .uxl-error-msg {
    p {
      margin-bottom: 0;
    }
  }
`;
