/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';
interface Lookup {
  code: string;
  label?: string | null;
  description?: string | null;
  enumCode?: string | null;
}

export interface PaymentMethodExpiration {
  month: number;
  year: number;
}

interface CustomerPaymentMethodCoBrand {
  issuer: Lookup;
}

export interface CustomerPaymentMethod {
  createTimestamp?: string | Date | number;
  preferred: boolean;
  purpose: Lookup;
  type: Lookup;
  panLast4: string;
  expiration: PaymentMethodExpiration;
  nickname: string | null;
  issuerType: Lookup;
  cobrand: CustomerPaymentMethodCoBrand | null;
  id?: string;
  revisionToken?: string;
}

export interface KoreanConsent {
  checkboxTitle: string;
  type: string;
  key: string;
  checkboxDescription: string;
}

interface CustomerAddressDetails {
  country: Lookup;
}

interface CustomerAddress {
  address: CustomerAddressDetails;
  primary: boolean;
  type: string;
}

interface PreferredOptionCode {
  code: string;
}

interface Phones {
  countryCode: string;
  phoneNumber: string;
  phoneType: string;
}

interface CommunicationOptions {
  method: PreferredOptionCode;
  subscribed: boolean;
  type: PreferredOptionCode;
}
interface PreferredLanguage {
  code: string;
  description?: string;
}

export interface ConsentType {
  feature: {
    code: string;
  };
  value: string | null;
}
export interface UserProfile {
  profileAddress: CustomerAddress[];
  linkedAccount: string[]; // TO DO once we have linked account details coming in UXL
  paymentMethods: CustomerPaymentMethod[];
  isdataLoaded?: boolean;
  preferredLanguage?: PreferredLanguage;
  consentFeatures?: ConsentType[];
  phoneNumber?: Phones[];
  communicationOptions?: CommunicationOptions[];
}

export interface ProfileAccountState {
  setProfileDetails: (value: UserProfile) => void;
  profileDetails: UserProfile;
  isProfileUXLLoading?: boolean;
  setIsProfileUXLLoading: (val: boolean) => void;
  profileModalId: string;
  setProfileModalId: (value: string) => void;
  setPaymentId: (value: string) => void;
  paymentId: string;
  revisionToken: string;
  setRevisionToken: (value: string) => void;
  isRememberMeSet: boolean;
  setIsRememberMeSet: (val: boolean) => void;
  setKoreanUserConsents: (value: Array<KoreanConsent>, showMore?: string, showLess?: string, errMsg?: string) => void;
  koreaUserConsents?: Array<KoreanConsent> | undefined | null;
  openConsentFormProfileModalId?: string;
  setOpenConsentFormProfileModalId: (val?: string) => void;
  isUserConsentUpdated?: boolean;
  consentShowMoreLabe?: string;
  koreanUserPersonalError?: string;
  consentShowLessLabe?: string;
  userBaseCountryCode?: string;
  isRememberMeChecked: boolean;
  setUserBaseCountryCode: (val: string) => void;
  setIsUserConsentUpdated: (val: boolean) => void;
  setIsRememberMeChecked: (val: boolean | undefined) => void;
  isProfileNudgeRendered: boolean;
  setIsProfileNudgeRendered: (val: boolean | undefined) => void;
  isMobileNumberVerified: boolean;
  setIsMobileNumberVerified: (val: boolean) => void;
  mobileNumberToGenerateOtp?: string;
  setMobileNumberToGenerateOtp: (val: string) => void;
}

const initialState: ProfileAccountState = {
  setProfileDetails: () => {},
  setKoreanUserConsents: () => {},
  profileDetails: {
    profileAddress: [],
    linkedAccount: [],
    paymentMethods: [],
    isdataLoaded: false,
    preferredLanguage: {
      code: '',
      description: '',
    },
    consentFeatures: [],
    phoneNumber: [],
    communicationOptions: [],
  },
  isProfileUXLLoading: false,
  setIsProfileUXLLoading: () => {},
  setProfileModalId: () => {},
  profileModalId: '',
  setPaymentId: () => {},
  paymentId: '',
  revisionToken: '',
  setRevisionToken: () => {},
  isRememberMeSet: false,
  isRememberMeChecked: false,
  setIsRememberMeSet: () => {},
  setOpenConsentFormProfileModalId: () => {},
  setUserBaseCountryCode: () => {},
  setIsUserConsentUpdated: () => {},
  setIsRememberMeChecked: () => {},
  isProfileNudgeRendered: false,
  setIsProfileNudgeRendered: () => {},
  isMobileNumberVerified: false,
  setIsMobileNumberVerified: () => {},
  setMobileNumberToGenerateOtp: () => {},
};

const profileStore: StateCreator<ProfileAccountState> = set => ({
  ...initialState,
  setKoreanUserConsents: (value, showMore, showLess, errMsg) => {
    set(() => ({
      koreaUserConsents: value,
      consentShowMoreLabe: showMore,
      consentShowLessLabe: showLess,
      koreanUserPersonalError: errMsg,
    }));
  },
  setProfileDetails: value => {
    set(() => ({
      profileDetails: value,
    }));
  },
  setUserBaseCountryCode: val => {
    set(() => ({
      userBaseCountryCode: val,
    }));
  },
  setIsUserConsentUpdated: val => {
    set(() => ({
      isUserConsentUpdated: val,
    }));
  },
  setOpenConsentFormProfileModalId: value => {
    set(() => ({
      openConsentFormProfileModalId: value,
    }));
  },
  setIsProfileUXLLoading: value => {
    set(() => ({
      isProfileUXLLoading: value,
    }));
  },
  setProfileModalId: value => {
    set(() => ({
      profileModalId: value,
    }));
  },
  setPaymentId: value => {
    set(() => ({
      paymentId: value,
    }));
  },

  setIsRememberMeSet: value => {
    set(() => ({
      isRememberMeSet: value,
    }));
  },
  setRevisionToken: value => {
    set(() => ({
      revisionToken: value,
    }));
  },
  setIsRememberMeChecked: value => {
    set(() => ({
      isRememberMeChecked: value,
    }));
  },
  setIsProfileNudgeRendered: val => {
    set(() => ({
      isProfileNudgeRendered: val,
    }));
  },
  setIsMobileNumberVerified: val => {
    set(() => ({
      isMobileNumberVerified: val,
    }));
  },
  setMobileNumberToGenerateOtp: val => {
    set(() => ({
      mobileNumberToGenerateOtp: val,
    }));
  },
});

export const useProfileStore = createAppStore(profileStore, {
  usePersistentStore: false,
});
