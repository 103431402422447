// Styles for VerifyTransferPoints go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledVerifyTransferPoints = styled.div`
  .table-container {
    max-width: ${toRem(744)};
    width: 100%;
    &__header {
      border-top-left-radius: ${toRem(14)};
      border-top-right-radius: ${toRem(14)};
      background-color: ${baseVariables.color.neutral20};
    }
    &__section {
      gap: ${toRem(24)};
      border-left: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-bottom-left-radius: ${toRem(14)};
      border-bottom-right-radius: ${toRem(14)};
      padding-top: ${toRem(34)};
      padding-bottom: ${toRem(28)};
      border-right: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};

      &__amount-section {
        border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
      }

      &__value {
        text-align: end;
      }
    }
  }

  .transfernow_btn {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      width: auto;
    }
  }
`;
