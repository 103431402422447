// Styles for CreateAccountConsentsForm go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledCreateAccountConsentsForm = styled.div`
  .create-account-consents-form-container {
    @media ${baseVariables.mediaQuery.md} {
      margin-bottom: ${toRem(64)} !important;
    }

    @media ${baseVariables.mediaQuery.xl} {
      margin-bottom: ${toRem(56)} !important;
    }

    .create-account-consents-form-wrapper {
      background-color: ${baseVariables.color.base20};
      min-width: ${toRem(332)};
      padding: 0 !important;

      &__border {
        border-radius: 0 0 ${toRem(14)} ${toRem(14)};
        box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 ${baseVariables.shadows.boxShadowTile};
      }

      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(704)};
        width: calc(100% - ${toRem(32)});
      }

      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(1124)};
      }

      @media ${baseVariables.mediaQuery.xl} {
        top: ${toRem(-240)};
      }
    }
  }

  .create-account-consents-form-container.spacing {
    .create-account-consents-form-wrapper {
      padding-top: ${toRem(40)};

      @media ${baseVariables.mediaQuery.md} {
        padding-top: ${toRem(80)};
      }
    }
  }
  .link-underline {
    text-decoration: underline;
  }
  .create-account-submit-mobile {
    width: 100%;
  }
  @media ${baseVariables.mediaQuery.md} {
    .create-account-submit-mobile {
      width: auto;
    }
  }

  .submit_btn-wrapper {
    padding: ${toRem(16)} 0 ${toRem(32)} 0 !important;
    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(32)} ${toRem(52)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      padding: ${toRem(16)} ${toRem(98)} ${toRem(32)} ${toRem(98)} !important;
    }
  }
  .create-account-consent {
    padding: 0 ${toRem(16)} !important;
    @media ${baseVariables.mediaQuery.md} {
      padding: 0 ${toRem(60)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      padding: 0 ${toRem(96)} !important;
    }
    .consent-checkbox {
      label {
        &:before {
          outline: none;
        }
      }
    }
  }
  .submit_btn {
    width: 100%;
    max-width: ${toRem(360)};
    @media ${baseVariables.mediaQuery.md} {
      width: unset;
    }
  }
  .default-consent {
    padding: ${toRem(24)} ${toRem(16)} !important;

    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(32)} ${toRem(52)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      padding: ${toRem(32)} ${toRem(98)} !important;
    }

    &__description {
      padding: 0 ${toRem(16)} ${toRem(24)} ${toRem(16)} !important;
      @media ${baseVariables.mediaQuery.md} {
        padding: 0 ${toRem(52)} ${toRem(32)} ${toRem(52)} !important;
      }
      @media ${baseVariables.mediaQuery.lg} {
        padding: 0 ${toRem(98)} ${toRem(32)} ${toRem(98)} !important;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
  .copyright-wrapper {
    @media ${baseVariables.mediaQuery.md} {
      padding: 0 ${toRem(52)} !important;
    }
    @media ${baseVariables.mediaQuery.lg} {
      padding: 0 ${toRem(98)} 0 ${toRem(98)} !important;
    }
  }
`;
