// Styles for ProfileSectionHelper go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { SkeletonLoaderDuration } from '../../../modules';
import styled from 'styled-components';

export const StyledProfileSectionElement = styled.div`
  .profile-sections-wrapper {
    section {
      border-radius: ${toRem(14)};
      background-color: ${baseVariables.color.base20};
    }
  }
  .section-border {
    border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral20};
  }
  .section-title {
    background-color: transparent;
  }

  .border-line {
    border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral00};
    &:hover {
      font-weight: ${baseVariables.font.fontWeightRegular};
    }
  }

  .btn-editLink {
    background: transparent;
    :focus-visible {
      border: ${toRem(2)} solid ${baseVariables.color.base10};
    }
  }
  .member-number {
    color: ${baseVariables.color['neutral40']};
  }
  #remember-me + label {
    padding-left: ${toRem(18)};
  }
  .cardData {
    &__VS {
      width: ${toRem(44)};
      clip: rect(0 ${toRem(44)} ${toRem(28)} ${toRem(1)}) !important;
      top: -${toRem(2)} !important;
      position: absolute;
    }

    &__AX {
      width: ${toRem(32)};
      clip: rect(${toRem(24)} ${toRem(32)} ${toRem(52)} ${toRem(1)}) !important;
      top: -${toRem(24)} !important;
      position: absolute;
    }

    &__DC {
      width: ${toRem(40)};
      clip: rect(${toRem(64)} ${toRem(40)} ${toRem(92)} ${toRem(1)}) !important;
      top: -${toRem(64)} !important;
      position: absolute;
    }

    &__DS {
      width: ${toRem(44)} !important;
      clip: rect(${toRem(104)} ${toRem(44)} ${toRem(132)} ${toRem(1)}) !important;
      top: -${toRem(104)} !important;
      position: absolute;
    }

    &__JC {
      width: ${toRem(36)};
      clip: rect(${toRem(124)} ${toRem(36)} ${toRem(152)} ${toRem(1)}) !important;
      top: -${toRem(124)} !important;
      position: absolute;
    }

    &__MC {
      width: ${toRem(44)} !important;
      clip: rect(${toRem(189)} ${toRem(44)} ${toRem(217)} ${toRem(1)}) !important;
      top: -${toRem(189)} !important;
      position: absolute;
    }

    &__UP {
      width: ${toRem(44)};
      clip: rect(${toRem(230.08)} ${toRem(48)} ${toRem(258.08)} ${toRem(1)}) !important;
      top: -${toRem(230.08)} !important;
      position: absolute;
    }
    &__defaultLabel {
      color: ${baseVariables.color['alert30']};
    }
    &__expiryLabel {
      color: ${baseVariables.color['alert50']};
    }
    &__image {
      width: ${toRem(40)};
      height: ${toRem(22)};
    }
  }
  .min-width-max-content {
    min-width: max-content;
  }
  .image-wrapper {
    img {
      height: ${toRem(32)};
    }
  }
  .skeleton-style {
    width: 100%;
    &:after {
      animation-duration: ${SkeletonLoaderDuration};
    }

    &-header,
    &-line-item {
      height: ${toRem(24)};
    }
  }
  .item-gap {
    gap: ${toRem(32)};
  }
`;
