// Styles for TotalPoints go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledTotalPoints = styled.div`
  background-color: ${baseVariables.color['alert10']} !important;
  @media ${baseVariables.mediaQuery.sm} {
    border-radius: ${toRem(14)};
    box-sizing: border-box;
  }

  .card {
    &__header {
      @media ${baseVariables.mediaQuery.sm} {
        min-height: ${toRem(50)};
        border-bottom: ${toRem(2)} solid ${baseVariables.color['neutral20']};
        width: 100%;
      }
    }
  }
`;
