// Styles for NewPromotions go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledNewPromotions = styled.div<{ isPromotionsMoved: boolean }>`
  .new-promotion-loader {
    &__mobile {
      &__container {
        width: 100%;
      }
    }
  }
  .card {
    &__container {
      height: auto;
      ${({ isPromotionsMoved }) =>
        isPromotionsMoved &&
        `
        flex-direction: column;
        border-radius: 0rem 0rem ${toRem(16)} ${toRem(16)};
        box-shadow: none;
        @media ${baseVariables.mediaQuery.lg} {
          flex-direction: row;
        }
      `}
      ${({ isPromotionsMoved }) =>
        !isPromotionsMoved &&
        `
        @media ${baseVariables.mediaQuery.sm} {
          text-align: center;
        }
      `}
      
      .img-container {
        height: ${toRem(186)};
        ${({ isPromotionsMoved }) =>
          `
          @media ${isPromotionsMoved ? baseVariables.mediaQuery.lg : baseVariables.mediaQuery.sm} {
            height: auto;
          }
          @media ${baseVariables.mediaQuery.sm} {
            max-width: 100%;
          }

          .feature-img {
            object-fit: cover;
            height: ${toRem(186)};
            @media ${isPromotionsMoved ? baseVariables.mediaQuery.lg : baseVariables.mediaQuery.sm} {
              height: 100%;
            }
          }
        `}
      }
      &__skeleton {
        border-radius: ${toRem(14)};
        box-sizing: border-box;
        overflow: hidden;
        box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
        &__desc {
          width: 100%;
          height: ${toRem(24)};
        }
        &__label {
          width: 100%;
          height: ${toRem(48)};
        }
        &__btn {
          width: ${toRem(144)};
          height: ${toRem(34)};
        }
        &__img {
          width: 100%;
          height: ${toRem(186)};
          @media ${baseVariables.mediaQuery.md} {
            width: 50%;
            height: ${toRem(250)};
          }
        }
      }
      .content {
        background-color: ${baseVariables.color['alert10']} !important;
        height: auto;
        padding: ${toRem(24)} !important;
        ${({ isPromotionsMoved }) =>
          !isPromotionsMoved
            ? `
          @media ${baseVariables.mediaQuery.sm} {
            padding: ${toRem(32)} !important;
          }
          @media ${baseVariables.mediaQuery.lg} {
            padding: ${toRem(24)} ${toRem(40)} ${toRem(24)} ${toRem(56)} !important;
          }
          `
            : `@media ${baseVariables.mediaQuery.sm} {
              max-width: 100%;
            }
          `}

        .feature-header {
          display: flex;
        }
        .feature-text {
          color: ${baseVariables.color['bvyBase10']};
          display: flex;
        }
      }
    }
  }
  .border-line {
    border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral10']};
  }
  .feature-button {
    min-width: ${toRem(128)};
    text-align: center;
  }
`;
