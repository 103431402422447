import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledCombineAccountsConsent = styled.div`
  .form-bar {
    left: 0;
    border-bottom: ${toRem(1)} solid ${baseVariables.color.alert20};
  }
  .consent-button {
    .btn {
      width: 100%;
      @media ${baseVariables.mediaQuery.md} {
        width: auto;
      }
    }
  }
`;
