// Styles for PromotionNotAvailable go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledPromotionNotAvailable = styled.div`
  margin-top: ${toRem(40)};
  ul {
    padding-left: ${toRem(16)};
  }
  li {
    padding-bottom: ${toRem(8)};
  }
  .divider {
    border-bottom: ${toRem(2)} solid ${baseVariables.color['neutral30']};
  }
  .btn {
    min-width: ${toRem(154)};
    @media ${baseVariables.mediaQuery.sm} {
      min-width: ${toRem(128)};
    }
  }
  .join-btn {
    margin-left: 0;
  }

  .account-page-error-msg p {
    margin-bottom: 0;
  }
`;
