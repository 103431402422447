// Styles for TransferPoints go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledPointsToMiles = styled.div`
  .points-page-error-msg {
    p {
      margin: 0;
    }
  }

  .current-balance {
    border: ${toRem(1)} solid ${baseVariables.color.neutral30};
    border-radius: ${toRem(14)};
    gap: ${toRem(19)};

    &__loader {
      width: 40%;
      top: ${toRem(1)};
      height: ${toRem(32)};
    }
  }

  .text-loader {
    width: 100%;
    top: ${toRem(1)};
    height: ${toRem(24)};
  }

  .description-loader {
    width: 100%;
    top: ${toRem(1)};
    height: ${toRem(48)};
  }

  .icon-buy-points {
    align-self: flex-end;
    &::before {
      font-size: ${toRem(30)};
    }

    &__points {
      width: 100%;
      gap: ${toRem(4)};
    }
  }

  .m-input-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      height: ${toRem(45)} !important;
      &::-ms-reveal {
        display: none;
      }
    }
  }

  .transfer-details-container {
    &__header {
      border-top-left-radius: ${toRem(14)};
      border-top-right-radius: ${toRem(14)};
      background-color: ${baseVariables.color.neutral10};
    }
    &__form {
      border-left: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-bottom-left-radius: ${toRem(14)};
      border-bottom-right-radius: ${toRem(14)};
      border-right: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};

      &__partnerlabel {
        z-index: 1;
      }

      &__dropdown-container {
        margin-right: 0;
        @media ${baseVariables.mediaQuery.md} {
          margin-right: ${toRem(12)};
        }

        &__dropdown {
          width: 100%;
          height: ${toRem(44.67)};
          border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
          border-color: ${baseVariables.color['neutral40']};
          padding-top: ${toRem(12)} !important;
          padding-bottom: ${toRem(12)} !important;
          .icon-dropdown-down,
          .icon-dropdown-up {
            position: absolute;
            right: ${toRem(8)};
          }
          .dropdown-value {
            color: ${baseVariables.color['neutral40']};
            font-size: ${toRem(16)};
          }
        }
      }

      &__validation-msg {
        color: ${baseVariables.color['alert50']};
      }

      &__points {
        flex: 1;
      }

      &__separator {
        color: ${baseVariables.color.neutral40};
      }

      &__converted-points {
        color: ${baseVariables.color.neutral40} !important;
      }
    }
  }

  .partner-instruction {
    gap: ${toRem(8)};
  }

  .input-valid-check {
    position: absolute;
    top: ${toRem(4)};
    right: ${toRem(16)};
    left: initial;
    transform: translateY(50%);
  }

  .user-instruction {
    p {
      margin-bottom: 0;
    }
  }

  .continue_btn {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      width: auto;
    }
  }
  .m-dropdown-container {
    .m-dropdown-children {
      height: auto;
      max-height: ${toRem(224)};
    }
  }
`;
