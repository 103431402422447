// Styles for TransferPoints go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { constants } from '../../../modules';

export const StyledTransferPoints = styled.div`
  .points-page-error-msg {
    p {
      margin: 0;
    }
  }

  .subheader {
    padding-bottom: ${toRem(32)};
    @media ${baseVariables.mediaQuery.lg} {
      padding-bottom: ${toRem(48)};
    }
  }

  .current-balance {
    width: 100%;
    margin-bottom: ${toRem(28)} !important;
    border: 1px solid ${baseVariables.color.neutral30};
    border-radius: ${toRem(14)};
    gap: ${toRem(19)};
    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(35)} !important;
    }

    &__points {
      width: 100%;
      gap: ${toRem(4)};
    }
    &__label {
      position: relative;
      top: ${toRem(1)};
      font-weight: ${constants.BOLD_FONT_WEIGHT};
    }
    &__loader {
      width: 40%;
      top: ${toRem(1)};
      height: ${toRem(24)};
    }
  }

  .icon-buy-points {
    align-self: flex-end;
    &::before {
      font-size: ${toRem(30)};
    }
  }

  .m-input-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      height: ${toRem(45)} !important;
      &::-ms-reveal {
        display: none;
      }
    }
  }

  .transfer-details-container {
    &__header {
      border-top-left-radius: ${toRem(14)};
      border-top-right-radius: ${toRem(14)};
      background-color: ${baseVariables.color.neutral20};
    }
    &__form {
      border-left: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-bottom-left-radius: ${toRem(14)};
      border-bottom-right-radius: ${toRem(14)};
      border-right: ${toRem(1)} solid ${baseVariables.color.neutral30};
      border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};

      &__partnerlabel {
        z-index: 1;
      }

      &__dropdown-container {
        margin-right: 0px;
        @media ${baseVariables.mediaQuery.md} {
          margin-right: ${toRem(12)};
        }

        &__dropdown {
          width: 100%;
          height: ${toRem(44.67)};
          border-radius: ${toRem(4)} !important; //important is given to override the dropdown css property
          border-color: ${baseVariables.color['neutral40']};
          padding-top: ${toRem(12)} !important;
          padding-bottom: ${toRem(12)} !important;
          .icon-dropdown-down,
          .icon-dropdown-up {
            position: absolute;
            right: ${toRem(8)};
          }
          .dropdown-value {
            color: ${baseVariables.color['neutral40']};
            font-size: ${toRem(16)};
          }
        }
      }

      &__validation-msg {
        color: ${baseVariables.color['alert50']};
        a {
          color: ${baseVariables.color['alert50']};
        }
      }

      &__unlinked-msg {
        &__text {
          color: ${baseVariables.color['alert50']} !important;
        }
        &__link {
          border-bottom: ${toRem(1)} solid ${baseVariables.color['alert50']};
        }
      }

      &__sectionSubHeader {
        @media ${baseVariables.mediaQuery.md} {
          padding-top: ${toRem(40)} !important;
        }
      }

      &__points {
        flex: 1;
      }

      &__separator {
        color: ${baseVariables.color.neutral40};
      }

      &__converted-points {
        color: ${baseVariables.color.neutral40}!important;
      }
    }
  }

  .partner-instruction {
    gap: ${toRem(8)};
  }

  .input-valid-check {
    position: absolute;
    top: ${toRem(4)};
    right: ${toRem(17)};
    left: initial;
    transform: translateY(50%);
  }

  .user-instruction {
    padding-top: ${toRem(56)} !important;
  }

  .continue_btn {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      width: auto;
    }
  }

  .message-gap {
    gap: ${toRem(4)};
  }

  .label-error,
  .icon-info {
    color: ${baseVariables.color.alert50};
  }
`;
