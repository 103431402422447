// Styles for SignInContentBlock go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { getContentBlockImageDimensions } from '../../modules';

export const StyledSignInContentBlock = styled.div<{ overlay: boolean }>`
    padding-left: ${toRem(24)};
    padding-right: ${toRem(24)};
    max-width: ${toRem(458)};
    &.respath-wrapper {
      padding-left: ${toRem(16)} !important;
      padding-right: ${toRem(16)} !important;
      border-top: 0;
        @media ${baseVariables.mediaQuery.lg} {
          padding-left: ${toRem(48)} !important;
          border-left: 0;
          border-top: none;
          border-bottom: 0;
        }
    }
    @media ${baseVariables.mediaQuery['sm']} {
      padding-left: ${toRem(48)};
      padding-right: 0;
    }
    .respath-heading {
      line-height: ${toRem(26)};
    }
    .width-mobile-100 {
      width: 100%;
      @media ${baseVariables.mediaQuery['sm']} {
        width: auto;
      }
    }
      
    .point-list {
      line-height: ${toRem(18)};
      ul {
        padding-left: ${toRem(16)}; // to override RTE styles
      }
      li {
        padding-bottom: ${toRem(8)}; // to override RTE styles
        p {
          margin: 0; // to override RTE styles
        }
        &:last-child {
          padding-bottom: 0 !important;
        }
      }
    }
    &.signin-img-block {
      .logo-container {
        margin-bottom: ${toRem(36)};
        .logo {
          font-size: ${toRem(58)};
        }
      }
      img {
        width: 100%;
        min-height: ${toRem(210)} !important;
        max-width: ${toRem(getContentBlockImageDimensions('all'))} !important;
        @media ${baseVariables.mediaQuery.sm} {
          min-height: ${(props: { overlay: boolean }) => (props?.overlay ? toRem(229) : toRem(210))} !important;
          max-width: ${(props: { overlay: boolean }) =>
            toRem(getContentBlockImageDimensions('sm', props?.overlay))} !important;
        }
        @media ${baseVariables.mediaQuery.md} {
          min-height: ${(props: { overlay: boolean }) => (props?.overlay ? toRem(288) : toRem(229))} !important;
          max-width: ${(props: { overlay: boolean }) =>
            toRem(getContentBlockImageDimensions('md', props?.overlay))} !important;
        }
        @media ${baseVariables.mediaQuery.lg} {
          min-height: ${toRem(288)} !important;
          max-width: ${toRem(getContentBlockImageDimensions('lg'))} !important;
        }
    }
`;

export const StyledSignInContentBlockFooter = styled.div`
  margin-right: ${toRem(-8)};
  .footer-container {
    border-top: ${toRem(2)} solid ${baseVariables.color['neutral30']};
    padding-left: ${toRem(24)};
    padding-right: ${toRem(24)};
    @media ${baseVariables.mediaQuery.md} {
      padding-left: ${toRem(48)} !important;
    }
  }
`;

export const StyledSignInContentBlockContainer = styled.div<{ isResLookUpVariation: boolean }>`
  @media ${baseVariables.mediaQuery.md} {
    max-width: ${(props: { isResLookUpVariation: boolean }) => (props?.isResLookUpVariation ? toRem(507) : 'auto')};
  }
  @media ${baseVariables.mediaQuery.lg} {
    max-width: ${(props: { isResLookUpVariation: boolean }) => (props?.isResLookUpVariation ? toRem(490) : 'auto')};
  }
  height: 100%;
  .signin-btn {
    width: fit-content;
  }
  .res-block {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      max-width: ${toRem(507)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      max-width: ${toRem(490)};
    }
  }
  .horizontal-bar {
    width: ${toRem(80)};
    color: ${baseVariables.color['neutral30']};
  }
  .content-icon {
    font-size: ${toRem(40)};
  }
  .icon-box-container {
    display: none;
    @media ${baseVariables.mediaQuery.md} {
      display: block;
    }
  }
  .icon-box-container-overlay {
    display: none;
    @media ${baseVariables.mediaQuery.sm} {
      display: block;
    }
  }
  .join-now-btn {
    width: 100%;
    @media ${baseVariables.mediaQuery.md} {
      width: max-content;
    }
  }
  .join-now-btn-overlay {
    width: 100%;
    @media ${baseVariables.mediaQuery.sm} {
      width: max-content;
    }
  }
  .icon-container {
    gap: ${toRem(16)};
    display: flex;
    width: 100%;
    &__detail {
      flex: 1;
    }
  }
  .content-block-description {
    p {
      margin-bottom: 0; //to over ride <p> tag css inside richText
    }
  }
`;
