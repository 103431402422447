import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { getColorByCode } from '../../../modules/utils';

interface ProfileImageProps {
  colorCode?: string;
}

export const ProfileInfoStyle = styled.div<ProfileImageProps>`
  .profileinfo {
    &__category,
    &__name {
      color: ${props => getColorByCode(props?.colorCode ?? '', false)};
      word-break: break-word;
      text-transform: capitalize;
    }

    .t-numbers-l-custom {
      font-size: ${toRem(44)};
      line-height: ${toRem(72)};
      letter-spacing: 0;
      @media ${baseVariables.mediaQuery.desktop} {
        font-size: ${toRem(64)};
        line-height: ${toRem(68)};
      }
    }

    .t-category-custom {
      font-size: ${toRem(14)};
      line-height: ${toRem(20)};
      font-weight: 500;
      @media ${baseVariables.mediaQuery.desktop} {
        font-size: ${toRem(20)};
        line-height: ${toRem(26)};
      }
    }
  }
`;

// TODO - removing this member image CSS for now - it will be enable in future

// export const ProfileImageStyle = styled.div<ProfileImageProps>`
//   .profile-picture {
//     position: relative;
//     border-radius: 50%;
//     background-repeat: no-repeat;
//     background-position: center center;
//     background-size: cover;
//     height: ${toRem(78)};
//     width: ${toRem(78)};
//     margin-right: ${toRem(8)};
//     border: 0.5rem solid ${props => getColorByCode(props.colorCode)};

//     @media ${baseVariables.mediaQuery.desktop} {
//       height: ${toRem(148)};
//       width: ${toRem(148)};
//       margin-right: ${toRem(24)};
//     }
//     .icon-lifetime {
//       position: absolute;
//       bottom: ${toRem(-12)};
//       left: ${toRem(-20)};
//       border-radius: 100%;
//       text-align: center;
//       width: ${toRem(40)};
//       height: ${toRem(40)};
//       color: ${theme.color['base-20']};
//       padding: ${toRem(2)};
//       transform: rotate(-40deg);
//       border: ${toRem(5)} solid ${theme.color['base-20']};
//       font-size: ${toRem(20)};
//       background: ${props => getColorByCode(props.colorCode)};

//       @media ${baseVariables.mediaQuery.desktop} {
//         bottom: ${toRem(4)};
//         left: ${toRem(-16)};
//       }
//     }
//     .icon-nav---account-alt-active {
//       font-size: ${toRem(80)};
//       background-repeat: no-repeat;
//       background-position: center center;
//       background-size: cover;
//       height: ${toRem(56)};
//       display: flex;
//       color: gray;
//       align-items: center;
//       justify-content: center;
//       @media ${baseVariables.mediaQuery.desktop} {
//         font-size: ${toRem(160)}
//         height: ${toRem(120)};
//       }
//     }
//   }
// `;
