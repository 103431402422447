import styled from 'styled-components';
import { baseVariables as theme, toRem } from '@marriott/mi-ui-library';
import { SkeletonLoaderDuration } from '../../modules';

export const StyledMyActivity = styled.div`
  .table-responsive {
    border: ${toRem(2)} solid ${theme.color['neutral10']};
    border-radius: ${toRem(16)};
    background-color: ${theme.color['base20']};
  }
  .fade-txt {
    color: ${theme.color['neutral40']};
  }

  .border-line {
    border-bottom: ${toRem(1)} solid ${theme.color['neutral10']};
  }

  .button-bg-color {
    background-color: ${theme.color['base20']};
  }
  .custom-hotel-name {
    &:hover {
      text-decoration: underline;
    }
  }

  .skeleton-style {
    width: 100%;
    height: ${toRem(20)};
    &:after {
      animation-duration: ${SkeletonLoaderDuration};
    }
    &-filter-loader {
      height: ${toRem(100)};
    }
    &-table-loader {
      height: ${toRem(400)};
    }
  }

  .activity-pagination {
    .dropdown {
      .m-custom-select-block {
        button {
          padding: ${toRem(8)};
        }
      }
    }
  }

  .customized-icon {
    font-size: ${toRem(16)};
    line-height: ${toRem(32)};
  }
`;
