import { baseVariables } from '@marriott/mi-ui-library';
import styled from 'styled-components';

export const StyledConsentForm = styled.div`
  .custom-scrollbar {
    //need this to access internal div of modal
    padding: 0;
  }
  .consent-form-footer {
    background-color: ${baseVariables.color['base20']};
    border-top-color: ${baseVariables.color['neutral30']};
  }
`;
