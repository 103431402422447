import { FC, useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Icon, RichText, Heading, Types, InputTextField } from '@marriott/mi-ui-library';
import {
  PageContext,
  addClassToBlankTargetLinks,
  constants,
  getCountryAndLangCode,
  scrollToClass,
  shouldSwapName,
  checkChinaLocale,
  nameFieldRegex,
} from '../../modules';
import { usecombineAccountsStore } from '../../modules/store/combineAccountsStore';
import { PhoneNumberField } from '../../molecules/PhoneNumberField';
import { Dropdown } from '../../molecules/Dropdown';
import { StyledCombineAccount } from './CombineAccounts.styles';
import {
  CombineAccountsProps,
  CountriesCodeList,
  CountryDialCodeList,
  MobileNumberType,
} from './CombineAccounts.types';

export const CombineAccounts: FC<CombineAccountsProps> = (props: CombineAccountsProps) => {
  const pageContext = useContext(PageContext);
  const sessionData = pageContext?.sessionData?.cacheData?.data;
  const currentLocaleCountry = getCountryAndLangCode(pageContext?.currentLocale ?? 'en_US')?.countryCode;
  const currentLocale = pageContext?.currentLocale;
  const localeCountryCode = currentLocaleCountry || constants.USA_COUNTRY_CODE;
  const localeBasedNameSwapConfig = pageContext?.localeBasedNameSwapConfig;
  const [reverseNamefield, setReverseNamefield] = useState(false);
  const authorModelData = props?.model;
  const [notesRte, setNotesRte] = useState('');
  const { combineAccountFormFields, setCombineAccountFormFields } = usecombineAccountsStore(state => state); //combineAccount state variables
  const [selectedCountry, setSelectedCountry] = useState(
    authorModelData?.countries?.length
      ? { id: authorModelData?.countries?.[0].id, value: authorModelData?.countries?.[0].value }
      : null
  ); //country dropdown initial value
  const countriesDialCodes = authorModelData?.countries?.map(
    (country: { id?: string; countryDialCode?: string; value?: string }) => ({
      id: country?.id ?? '',
      value: country?.countryDialCode ?? '',
    })
  );

  const countriesModifiedList = useMemo(() => {
    return authorModelData?.countries?.map(country => {
      return { ...country, value: country.countryDialCode, name: country.value };
    });
  }, [authorModelData?.countries]);

  const [selectedPhoneCountry, setSelectedPhoneCountry] = useState(
    countriesDialCodes?.length ? countriesDialCodes?.[0] : null
  ); //phone number dropdown initial value
  const [isMemeberMax, setIsMemberMax] = useState(false);

  //To be updated: email needs to be fetched either from UXL or BE to be validated by second email form on submit- for now adding FE validations
  // const [email, setEmail] = useState('');

  useEffect(() => {
    //set country to be selected
    if (authorModelData?.countries?.length) {
      authorModelData?.countries.forEach((country: CountriesCodeList) => {
        if (currentLocaleCountry === country.id) {
          setSelectedCountry(country);
          setCombineAccountFormFields({
            ...combineAccountFormFields,
            formFields: {
              ...combineAccountFormFields?.formFields,
              second_country: { value: country?.value, id: country?.id },
            },
          });
        }
      });
    }

    //set phone number dropdown to be selected
    if (countriesDialCodes?.length) {
      countriesDialCodes.forEach((country: CountryDialCodeList) => {
        if (currentLocaleCountry === country?.id) {
          setSelectedPhoneCountry({ id: country.id, value: country.value });
        }
      });
    }
    //set external link on page load
    setNotesRte(addClassToBlankTargetLinks(authorModelData?.notesDescription ?? ''));
  }, []);

  useEffect(() => {
    const qrCodeContainer = document.getElementById('notes');
    let spanTag: HTMLSpanElement;
    // access the id- learmore where we have to implement modal trigger
    if (qrCodeContainer) {
      spanTag = qrCodeContainer.querySelector('#qrCode') as HTMLSpanElement;
      if (spanTag) {
        // spanTag.insertAdjacentHTML('beforeend', htmlCode);
        spanTag.insertAdjacentHTML(
          'beforeend',
          `<span class="qr-code">
          <span class="combine-account-qr-arrow"> </span>
        <img src=${authorModelData?.featuredDynamicImage?.assetPath} alt=${authorModelData?.featuredDynamicImage?.altText}>
      </span>`
        );
        spanTag.addEventListener('mouseenter', () => {
          spanTag.classList.add('span-hovered');
        });
        spanTag.addEventListener('mouseleave', () => {
          spanTag.classList.remove('span-hovered');
        });
      }
    }
  }, [notesRte]);

  useEffect(() => {
    const localeSwapCheck = shouldSwapName(localeBasedNameSwapConfig, localeCountryCode);
    if (localeSwapCheck) {
      setReverseNamefield(true);
    } else {
      setReverseNamefield(false);
    }
  }, []);

  // set mobile number in the code and number format
  const setmobileNumber = (obj: MobileNumberType) => {
    setCombineAccountFormFields({
      ...combineAccountFormFields,
      formFields: {
        ...combineAccountFormFields?.formFields,
        phoneNumber: obj.mobileNumber ? `${obj?.countryCode} ${obj?.mobileNumber}` : '',
      },
    });
  };
  //country change handler
  const handleCountryNameChange = (option: CountryDialCodeList) => {
    setSelectedCountry(option);
    setCombineAccountFormFields({
      ...combineAccountFormFields,
      formFields: {
        ...combineAccountFormFields?.formFields,
        second_country: { ...option },
      },
    });
  };

  const isCombineAPIError =
    combineAccountFormFields?.errorState?.matchDetailsError || combineAccountFormFields?.errorState?.emailSent;
  const errorObj = [
    combineAccountFormFields?.errorState?.matchDetailsError ? authorModelData?.accountInfoMismatchError : '',
    combineAccountFormFields?.errorState?.emailSent ? authorModelData?.emailErrorMessage : '',
  ];

  useEffect(() => {
    if (isCombineAPIError) {
      scrollToClass();
    }
  }, [isCombineAPIError]);

  return (
    <StyledCombineAccount data-testid="combineaccounts" data-component-name="o-account-combineaccounts">
      {!combineAccountFormFields?.errorState?.submitted ? (
        <div className="combine-account container mt-5" data-testId="combine-accounts">
          <div className="col-lg-12 col-md-8 offset-md-2 offset-lg-0 col-12 px-lg-0 px-md-1 px-0">
            <div className="desc-container mb-5">
              <div
                className={clsx(
                  't-title-s',
                  !(
                    combineAccountFormFields?.errorState?.isAllEmpty ||
                    combineAccountFormFields?.errorState?.matchDetailsError
                  ) && 'mb-5'
                )}
              >
                {authorModelData?.header}
              </div>
              {/* Empty Field Error FE */}
              {combineAccountFormFields?.errorState?.isAllEmpty && (
                <div className="mt-3 mb-4" data-testId="all-empty">
                  <div className="m-message-inline error-sev1">
                    <div className="m-message-content-wrap">
                      <div className="m-message-content">
                        <div>{authorModelData?.errorMessageHeader}</div>
                        <ul>
                          <li className={combineAccountFormFields?.errorState?.firstName ? '' : 'd-none'}>
                            {authorModelData?.firstNameError}
                          </li>
                          <li className={combineAccountFormFields?.errorState?.firstNameRegExp ? '' : 'd-none'}>
                            {authorModelData?.firstNameBannerError}
                          </li>
                          <li className={combineAccountFormFields?.errorState?.lastName ? '' : 'd-none'}>
                            {authorModelData?.lastNameError}
                          </li>
                          <li className={combineAccountFormFields?.errorState?.lastNameRegExp ? '' : 'd-none'}>
                            {authorModelData?.lastNameBannerError}
                          </li>
                          <li className={combineAccountFormFields?.errorState?.memberNumber ? '' : 'd-none'}>
                            {authorModelData?.memberNumberError}
                          </li>
                          <li className={combineAccountFormFields?.errorState?.email ? '' : 'd-none'}>
                            {authorModelData?.emailError}
                          </li>
                          <li className={combineAccountFormFields?.errorState?.phone ? '' : 'd-none'}>
                            {authorModelData?.mobileError}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Match Details Error from BE */}
              {isCombineAPIError && (
                <div className="mt-3 mb-4" data-testId="match-error">
                  <div className="m-message-inline error-sev1">
                    <div className="m-message-content-wrap">
                      <div className="m-message-content">
                        {combineAccountFormFields?.errorState?.matchDetailsError && (
                          <div>{authorModelData?.errorMessageHeader}</div>
                        )}
                        {errorObj?.map((errorMessage?: string) => {
                          return <div>{errorMessage}</div>;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Heading
                variation={Types.headingType.subtitle}
                fontSize={Types.size.medium}
                titleText={authorModelData?.notesHeader}
                customClass="mb-3 t-subtitle-m"
              />
              <RichText text={notesRte} componentId="notes" customClass="notesRte pl-0" />
            </div>
            {/* form */}
            <div className="d-lg-flex form-container mb-5 justify-content-between">
              {/* Left Form */}
              <div className="left-container col-lg-5 px-0 px-md-1">
                <div className="t-subtitle-xl mb-4">{authorModelData?.accountsSubHeader}</div>
                {!reverseNamefield ? (
                  <>
                    {/* First Name Input Field */}
                    <div className="mb-5">
                      <InputTextField
                        infoLabel="mr-first-name"
                        label={authorModelData?.firstNameLabel}
                        inputValue={sessionData?.firstName ?? ''}
                        inputMaxLength={20}
                        getLabelProps={() => ({
                          htmlFor: 'firstName',
                        })}
                        className="m-input-field is-active"
                        getInputProps={() => ({
                          /** setReadOnly props for remeber case */
                          readOnly: true,
                          autoComplete: 'off',
                          id: 'firstName',
                        })}
                        customDownshiftLabel={true}
                      />
                      {checkChinaLocale(currentLocale || '') && (
                        <div className="t-font-xs mt-2">{authorModelData?.firstNameDescription}</div>
                      )}
                    </div>
                    {/* Last Name Input Field */}
                    <div className="mb-5">
                      <InputTextField
                        infoLabel="mr-last-name"
                        label={authorModelData?.lastNameLabel}
                        inputValue={sessionData?.lastName ?? ''}
                        inputMaxLength={20}
                        getLabelProps={() => ({
                          htmlFor: 'lastName',
                        })}
                        className="m-input-field is-active"
                        getInputProps={() => ({
                          /** setReadOnly props for remeber case */
                          readOnly: true,
                          autoComplete: 'off',
                          id: 'lastName',
                        })}
                        customDownshiftLabel={true}
                      />
                      {checkChinaLocale(currentLocale || '') && (
                        <div className="t-font-xs mt-2">{authorModelData?.lastNameDescription}</div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {/* Last Name Input Field */}
                    <div className="mb-5">
                      <InputTextField
                        infoLabel="mr-last-name"
                        label={authorModelData?.lastNameLabel}
                        inputValue={sessionData?.lastName ?? ''}
                        inputMaxLength={20}
                        getLabelProps={() => ({
                          htmlFor: 'lastName',
                        })}
                        className="m-input-field is-active"
                        getInputProps={() => ({
                          /** setReadOnly props for remeber case */
                          readOnly: true,
                          autoComplete: 'off',
                          id: 'lastName',
                        })}
                        customDownshiftLabel={true}
                      />
                      {checkChinaLocale(currentLocale || '') && (
                        <div className="t-font-xs mt-2">{authorModelData?.lastNameDescription}</div>
                      )}
                    </div>
                    {/* First Name Input Field */}
                    <div className="mb-5">
                      <InputTextField
                        infoLabel="mr-first-name"
                        label={authorModelData?.firstNameLabel}
                        inputValue={sessionData?.firstName ?? ''}
                        inputMaxLength={20}
                        getLabelProps={() => ({
                          htmlFor: 'firstName',
                        })}
                        className="m-input-field is-active"
                        getInputProps={() => ({
                          /** setReadOnly props for remeber case */
                          readOnly: true,
                          autoComplete: 'off',
                          id: 'firstName',
                        })}
                        customDownshiftLabel={true}
                      />
                      {checkChinaLocale(currentLocale || '') && (
                        <div className="t-font-xs mt-2">{authorModelData?.firstNameDescription}</div>
                      )}
                    </div>
                  </>
                )}
                {/* Marriott Bonvoy Number Input Field */}
                <div>
                  <InputTextField
                    infoLabel="mr-bonvoyNumber"
                    label={authorModelData?.memberNumberLabel}
                    inputValue={sessionData?.rewardsId ?? ''}
                    inputMaxLength={9}
                    getLabelProps={() => ({
                      htmlFor: 'bonvoyNumber',
                    })}
                    className="m-input-field is-active mb-5"
                    getInputProps={() => ({
                      /** setReadOnly props for remeber case */
                      readOnly: true,
                      autoComplete: 'off',
                      id: 'bonvoyNumber',
                    })}
                    customDownshiftLabel={true}
                  />
                </div>
                {/* Phone Number Input Field */}
                <div className={clsx('m-input-field is-active mb-5 phone-number')}>
                  <PhoneNumberField
                    label={authorModelData?.mobilePhoneLabel ?? ''}
                    defaultCCOption={selectedPhoneCountry?.value ?? ''}
                    mobileInputVal={''}
                    dropdownCCOptions={countriesModifiedList ?? []}
                    isInputError={
                      combineAccountFormFields?.errorState?.isAllEmpty && combineAccountFormFields?.errorState?.phone
                    }
                    disabled={false}
                    fetchMobileNumber={(obj: MobileNumberType) => setmobileNumber(obj)}
                    inputErrorMsg={''}
                    isNonChinaMobileEnabled={true}
                    showIdandValueinOptions={true}
                  />
                  {combineAccountFormFields?.errorState?.isAllEmpty && combineAccountFormFields?.errorState?.phone && (
                    <Icon iconClass="icon password-valid-check icon-clear red-clear icon-s" />
                  )}
                  {combineAccountFormFields?.errorState?.isAllEmpty && combineAccountFormFields?.errorState?.phone && (
                    <div className="t-font-xs validation-msg mt-1">{authorModelData?.mobileError}</div>
                  )}
                  <div className="t-font-xs mt-2">{authorModelData?.mobileLabel}</div>
                </div>
              </div>

              {/* middle bar */}
              <div className="form-bar my-5 my-lg-0"></div>

              {/* right form */}
              <div className="right-container col-lg-5 px-0 px-md-1">
                <div className="t-subtitle-xl mb-4">{authorModelData?.yourSecondAccountLabel}</div>
                {!reverseNamefield ? (
                  <>
                    {/* First Name Input Field */}
                    <div className="m-input-field mb-5">
                      <InputTextField
                        infoLabel="sfg-firstname"
                        label={authorModelData?.firstNameLabel}
                        inputValue={combineAccountFormFields?.formFields?.second_firstName}
                        inputMaxLength={20}
                        getLabelProps={() => ({
                          htmlFor: 'second_firstName',
                        })}
                        className={clsx(
                          'm-input-field',
                          (combineAccountFormFields?.errorState?.isAllEmpty &&
                            combineAccountFormFields?.errorState?.firstName) ||
                            combineAccountFormFields?.errorState?.matchDetailsError ||
                            combineAccountFormFields?.errorState?.firstNameRegExp
                            ? 'is-error'
                            : 'is-active'
                        )}
                        getInputProps={() => ({
                          /** setReadOnly props for remeber case */
                          autoComplete: 'off',
                          id: 'second_firstName',
                        })}
                        getInputValue={(val: string) => {
                          setCombineAccountFormFields({
                            ...combineAccountFormFields,
                            formFields: {
                              ...combineAccountFormFields?.formFields,
                              second_firstName: val?.trim(),
                            },
                            errorState: {
                              ...combineAccountFormFields?.errorState,
                              firstName: false,
                              firstNameRegExp: val && !nameFieldRegex.test(val),
                            },
                          });
                        }}
                        showErrorMessage={
                          (combineAccountFormFields?.errorState?.firstName &&
                            combineAccountFormFields?.errorState?.isAllEmpty) ||
                          combineAccountFormFields?.errorState?.firstNameRegExp
                        }
                        messageToShow={
                          combineAccountFormFields?.errorState?.firstName
                            ? authorModelData?.firstNameError
                            : authorModelData?.nameInlineErrorMsg
                        }
                        messageClass="validation-msg"
                        customDownshiftLabel={true}
                      />
                      {((combineAccountFormFields?.errorState?.isAllEmpty &&
                        combineAccountFormFields?.errorState?.firstName) ||
                        combineAccountFormFields?.errorState?.matchDetailsError ||
                        combineAccountFormFields?.errorState?.firstNameRegExp) && (
                        <Icon iconClass="icon password-valid-check icon-clear red-clear icon-s" />
                      )}
                      {checkChinaLocale(currentLocale || '') && (
                        <div className="t-font-xs mt-2">{authorModelData?.firstNameDescription}</div>
                      )}
                    </div>
                    {/* Last Name Input Field */}
                    <div className="m-input-field mb-5">
                      <InputTextField
                        infoLabel="sfg-lastName"
                        label={authorModelData?.lastNameLabel}
                        inputValue={combineAccountFormFields?.formFields?.second_lastName}
                        inputMaxLength={20}
                        getLabelProps={() => ({
                          htmlFor: 'second_lastName',
                        })}
                        className={clsx(
                          'm-input-field',
                          (combineAccountFormFields?.errorState?.isAllEmpty &&
                            combineAccountFormFields?.errorState?.lastName) ||
                            combineAccountFormFields?.errorState?.matchDetailsError ||
                            combineAccountFormFields?.errorState?.lastNameRegExp
                            ? 'is-error'
                            : 'is-active'
                        )}
                        getInputProps={() => ({
                          /** setReadOnly props for remeber case */
                          autoComplete: 'off',
                          id: 'second_lastName',
                        })}
                        getInputValue={(val: string) => {
                          setCombineAccountFormFields({
                            ...combineAccountFormFields,
                            formFields: {
                              ...combineAccountFormFields?.formFields,
                              second_lastName: val?.trim(),
                            },
                            errorState: {
                              ...combineAccountFormFields?.errorState,
                              lastName: false,
                              lastNameRegExp: val && !nameFieldRegex.test(val),
                            },
                          });
                        }}
                        showErrorMessage={
                          (combineAccountFormFields?.errorState?.lastName &&
                            combineAccountFormFields?.errorState?.isAllEmpty) ||
                          combineAccountFormFields?.errorState?.lastNameRegExp
                        }
                        messageToShow={
                          combineAccountFormFields?.errorState?.lastName
                            ? authorModelData?.lastNameError
                            : authorModelData?.nameInlineErrorMsg
                        }
                        messageClass="validation-msg"
                        customDownshiftLabel={true}
                      />
                      {((combineAccountFormFields?.errorState?.isAllEmpty &&
                        combineAccountFormFields?.errorState?.lastName) ||
                        combineAccountFormFields?.errorState?.matchDetailsError ||
                        combineAccountFormFields?.errorState?.lastNameRegExp) && (
                        <Icon iconClass="icon password-valid-check icon-clear red-clear icon-s" />
                      )}
                      {checkChinaLocale(currentLocale || '') && (
                        <div className="t-font-xs mt-2">{authorModelData?.lastNameDescription}</div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {/* Last Name Input Field */}
                    <div className="m-input-field mb-5">
                      <InputTextField
                        infoLabel="sfg-lastName"
                        label={authorModelData?.lastNameLabel}
                        inputValue={combineAccountFormFields?.formFields?.second_lastName}
                        inputMaxLength={20}
                        getLabelProps={() => ({
                          htmlFor: 'second_lastName',
                        })}
                        className={clsx(
                          'm-input-field',
                          (combineAccountFormFields?.errorState?.isAllEmpty &&
                            combineAccountFormFields?.errorState?.lastName) ||
                            combineAccountFormFields?.errorState?.matchDetailsError ||
                            combineAccountFormFields?.errorState?.lastNameRegExp
                            ? 'is-error'
                            : 'is-active'
                        )}
                        getInputProps={() => ({
                          /** setReadOnly props for remeber case */
                          autoComplete: 'off',
                          id: 'second_lastName',
                        })}
                        getInputValue={(val: string) => {
                          setCombineAccountFormFields({
                            ...combineAccountFormFields,
                            formFields: {
                              ...combineAccountFormFields?.formFields,
                              second_lastName: val?.trim(),
                            },
                            errorState: {
                              ...combineAccountFormFields?.errorState,
                              lastName: false,
                              lastNameRegExp: val && !nameFieldRegex.test(val),
                            },
                          });
                        }}
                        showErrorMessage={
                          (combineAccountFormFields?.errorState?.lastName &&
                            combineAccountFormFields?.errorState?.isAllEmpty) ||
                          combineAccountFormFields?.errorState?.lastNameRegExp
                        }
                        messageToShow={
                          combineAccountFormFields?.errorState?.lastName
                            ? authorModelData?.lastNameError
                            : authorModelData?.nameInlineErrorMsg
                        }
                        messageClass="validation-msg"
                        customDownshiftLabel={true}
                      />
                      {((combineAccountFormFields?.errorState?.isAllEmpty &&
                        combineAccountFormFields?.errorState?.lastName) ||
                        combineAccountFormFields?.errorState?.matchDetailsError ||
                        combineAccountFormFields?.errorState?.lastNameRegExp) && (
                        <Icon iconClass="icon password-valid-check icon-clear red-clear icon-s" />
                      )}
                      {checkChinaLocale(currentLocale || '') && (
                        <div className="t-font-xs mt-2">{authorModelData?.lastNameDescription}</div>
                      )}
                    </div>{' '}
                    {/* First Name Input Field */}
                    <div className="m-input-field mb-5">
                      <InputTextField
                        infoLabel="sfg-firstname"
                        label={authorModelData?.firstNameLabel}
                        inputValue={combineAccountFormFields?.formFields?.second_firstName}
                        inputMaxLength={20}
                        getLabelProps={() => ({
                          htmlFor: 'second_firstName',
                        })}
                        className={clsx(
                          'm-input-field',
                          (combineAccountFormFields?.errorState?.isAllEmpty &&
                            combineAccountFormFields?.errorState?.firstName) ||
                            combineAccountFormFields?.errorState?.matchDetailsError ||
                            combineAccountFormFields?.errorState?.firstNameRegExp
                            ? 'is-error'
                            : 'is-active'
                        )}
                        getInputProps={() => ({
                          /** setReadOnly props for remeber case */
                          autoComplete: 'off',
                          id: 'second_firstName',
                        })}
                        getInputValue={(val: string) => {
                          setCombineAccountFormFields({
                            ...combineAccountFormFields,
                            formFields: {
                              ...combineAccountFormFields?.formFields,
                              second_firstName: val?.trim(),
                            },
                            errorState: {
                              ...combineAccountFormFields?.errorState,
                              firstName: false,
                              firstNameRegExp: val && !nameFieldRegex.test(val),
                            },
                          });
                        }}
                        showErrorMessage={
                          (combineAccountFormFields?.errorState?.firstName &&
                            combineAccountFormFields?.errorState?.isAllEmpty) ||
                          combineAccountFormFields?.errorState?.firstNameRegExp
                        }
                        messageToShow={
                          combineAccountFormFields?.errorState?.firstName
                            ? authorModelData?.firstNameError
                            : authorModelData?.nameInlineErrorMsg
                        }
                        messageClass="validation-msg"
                        customDownshiftLabel={true}
                      />
                      {(combineAccountFormFields?.errorState?.isAllEmpty &&
                        combineAccountFormFields?.errorState?.firstName) ||
                        combineAccountFormFields?.errorState?.matchDetailsError ||
                        (combineAccountFormFields?.errorState?.firstNameRegExp && (
                          <Icon iconClass="icon password-valid-check icon-clear red-clear icon-s" />
                        ))}
                      {checkChinaLocale(currentLocale || '') && (
                        <div className="t-font-xs mt-2">{authorModelData?.firstNameDescription}</div>
                      )}
                    </div>
                  </>
                )}
                {/* Marriott Bonvoy Number Input Field */}
                <div className="mb-5 m-input-field">
                  <InputTextField
                    infoLabel="sfg-bonvoyNumber"
                    label={authorModelData?.memberNumberLabel}
                    inputValue={combineAccountFormFields?.formFields?.second_bonvoyNumber}
                    inputMaxLength={9}
                    getLabelProps={() => ({
                      htmlFor: 'second_bonvoyNumber',
                    })}
                    className={clsx(
                      'm-input-field',
                      (combineAccountFormFields?.errorState?.isAllEmpty &&
                        combineAccountFormFields?.errorState?.memberNumber) ||
                        combineAccountFormFields?.errorState?.matchDetailsError
                        ? 'is-error'
                        : 'is-active'
                    )}
                    getInputProps={() => ({
                      /** setReadOnly props for remeber case */
                      autoComplete: 'off',
                      id: 'second_bonvoyNumber',
                    })}
                    getInputValue={(val: string) => {
                      setCombineAccountFormFields({
                        ...combineAccountFormFields,
                        formFields: {
                          ...combineAccountFormFields?.formFields,
                          second_bonvoyNumber: val,
                        },
                      });
                      if (val?.length === 9) {
                        setIsMemberMax(true);
                      } else {
                        setIsMemberMax(false);
                      }
                    }}
                    showErrorMessage={
                      combineAccountFormFields?.errorState?.memberNumber &&
                      combineAccountFormFields?.errorState?.isAllEmpty
                    }
                    messageToShow={
                      combineAccountFormFields?.errorState?.memberNumber ? authorModelData?.memberNumberError : ''
                    }
                    messageClass="validation-msg"
                    customDownshiftLabel={true}
                  />
                  {((combineAccountFormFields?.errorState?.isAllEmpty &&
                    combineAccountFormFields?.errorState?.memberNumber) ||
                    combineAccountFormFields?.errorState?.matchDetailsError) && (
                    <Icon iconClass="icon password-valid-check icon-clear red-clear icon-s" />
                  )}
                  {/* field size error msg */}
                  {isMemeberMax && (
                    <div className="t-font-xs validation-msg mt-1">{authorModelData?.memberNumberInlineError}</div>
                  )}
                  {/* look up text */}
                  <RichText
                    text={authorModelData?.lookupMemberNumber ?? ''}
                    componentId={'lookUp'}
                    customClass="t-font-xs mt-2"
                  />
                </div>
                {/* Email input field */}
                <div className="m-input-field">
                  <InputTextField
                    infoLabel="sfg-emailaddress"
                    label={authorModelData?.emailLabel}
                    inputValue={combineAccountFormFields?.formFields?.second_email}
                    inputMaxLength={80}
                    getLabelProps={() => ({
                      htmlFor: 'emailaddress',
                    })}
                    className={clsx(
                      'm-input-field mb-5',
                      (combineAccountFormFields?.errorState?.isAllEmpty &&
                        combineAccountFormFields?.errorState?.email) ||
                        combineAccountFormFields?.errorState?.matchDetailsError
                        ? 'is-error'
                        : 'is-active'
                    )}
                    getInputProps={() => ({
                      /** setReadOnly props for remeber case */
                      autoComplete: 'off',
                      id: 'emailaddress',
                    })}
                    getInputValue={(val: string) => {
                      setCombineAccountFormFields({
                        ...combineAccountFormFields,
                        formFields: {
                          ...combineAccountFormFields?.formFields,
                          second_email: val,
                        },
                      });
                    }}
                    showErrorMessage={
                      combineAccountFormFields?.errorState?.email && combineAccountFormFields?.errorState?.isAllEmpty
                    }
                    messageToShow={combineAccountFormFields?.errorState?.email ? authorModelData?.emailError : ''}
                    messageClass="validation-msg"
                    customDownshiftLabel={true}
                  />
                  {((combineAccountFormFields?.errorState?.isAllEmpty && combineAccountFormFields?.errorState?.email) ||
                    combineAccountFormFields?.errorState?.matchDetailsError) && (
                    <Icon iconClass="icon password-valid-check icon-clear red-clear icon-s" />
                  )}
                </div>
                {/* Country Input Field */}
                <div className="m-input-field is-active mb-5">
                  <label className="hotelCountry-label" htmlFor="scanHotelCountry" id="dropdown-label-hotel-country">
                    {authorModelData?.countryRegionLabel}
                  </label>
                  <Dropdown
                    defaultOption={selectedCountry?.value ?? ''}
                    dropdownOptions={authorModelData?.countries ?? []}
                    onChange={handleCountryNameChange}
                    customClassName={'mb-5 is-active'}
                    dropdownId="hotel-country"
                    id="scanHotelCountry"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="combine-account-confirmation pt-md-4">
            <Heading
              data-testid="confirmation-title"
              fontSize={Types.size.small}
              variation={Types.headingType.title}
              customClass="mt-5"
              titleText={authorModelData?.confirmationTitle}
            />
            <Heading
              data-testid="confirmation-description"
              customClass="mt-3"
              titleText={authorModelData?.confirmationDescription}
              variation={Types.headingType.subtitle}
              fontSize={Types.size.medium}
            />
          </div>
        </div>
      )}
    </StyledCombineAccount>
  );
};

export const CombineAccountsConfig = {
  emptyLabel: 'combineAccounts',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/combineaccounts`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CombineAccountsEditable = (props: any) => (
  <EditableComponent config={CombineAccountsConfig} {...props}>
    <CombineAccounts {...props} />
  </EditableComponent>
);
