// Styles for SignIn go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledSignInContainerDiv = styled.div<{ isReservation: boolean }>`
  max-width: ${(props: { isReservation: boolean }) => (props?.isReservation ? toRem(434) : toRem(458))};
  .m-link-action {
    font-size: ${baseVariables.font.fontS};
  }
  .remember-me__text {
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .width-mobile-100 {
    width: 100%;
    @media ${baseVariables.mediaQuery['sm']} {
      width: auto;
      min-width: ${toRem(132)};
    }
  }
  .error-label,
  .error-label * {
    font-size: ${baseVariables.font.fontXs};
    color: ${baseVariables.color.alert50} !important;

    p {
      margin: 0;
      padding: 0;
    }
  }
  .accordion-container {
    .accordion-item {
      border-top: 0;
      .accordion-header {
        .accordion-button {
          border-top: 0;
          width: auto;
          .accordion-arrow-icon {
            line-height: 1;
          }
        }
      }
    }
  }
  .form-field-contaioner {
    .m-input-field {
      input {
        caret-color: ${baseVariables.color['neutral40']};
        &::-ms-reveal {
          display: none;
        }
      }
      &.is-error {
        &:focus-within {
          label {
            color: ${baseVariables.color.alert50};
          }
        }
        input:focus {
          box-shadow: none;
          ouline: 0;
        }
      }
      &.disabled {
        label {
          color: ${baseVariables.color.alert20};
        }
        input {
          border-color: ${baseVariables.color.alert20};
          outline: 0;
          color: ${baseVariables.color.neutral40} !important;
          &:focus {
            outline: 0;
            border-color: ${baseVariables.color.alert20};
            box-shadow: none;
          }
        }
      }
    }
    .trailing-element {
      span {
        font-size: ${baseVariables.font.fontXl};
      }
    }

    input + span:not(.trailing-element) * {
      margin-bottom: 0;
      color: ${baseVariables.color.alert50};
      line-height: 1.3;
      padding-top: ${toRem(3)};
      padding-left: ${toRem(8)};
      padding-right: ${toRem(4)};
      a {
        font-weight: ${baseVariables.font.fontWeightBold};
      }
      u,
      a {
        padding: 0;
      }
    }
  }

  // changes for overridiing the css form homepage header.
  input[type='checkbox']:checked + label:after {
    display: block !important;
    background-color: transparent !important;
    content: '' !important;
    display: block !important;
    position: absolute !important;
    left: ${toRem(6)} !important;
    top: ${toRem(2)} !important;
    width: ${toRem(6)} !important;
    height: ${toRem(12)} !important;
    opacity: 1 !important;
    border-bottom: ${toRem(2)} solid ${baseVariables.color.base20} !important;
    border-right: ${toRem(2)} solid ${baseVariables.color.base20} !important;
    transition: border-color 0.3s ease !important;
  }

  input[type='checkbox']:checked + label:before {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: ${toRem(18)} !important;
    height: ${toRem(18)} !important;
    border: ${toRem(1)} solid ${baseVariables.color.base10} !important;
    border-radius: ${toRem(2)} !important;
    background: ${baseVariables.color.neutral00} !important;
    text-align: center !important;
  }

  .clear-remember-me {
    background-color: ${baseVariables.color.base20};
  }

  // changes for eaa-fcp-error wrapper
  .eaa-fcp-error {
    .accordion {
      &__container {
        .accordion {
          &__heading {
            justify-content: flex-start;

            .accordion {
              &__title {
                margin: 0;
              }
            }

            .icon-arrow-down,
            .icon-arrow-up {
              margin-left: ${toRem(8)};
            }
          }
          &__list {
            margin-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .eaa-sign-in-success-msg {
    p {
      margin-bottom: 0;
    }
  }
`;
