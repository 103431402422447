// Styles for PromotionDetails go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import styled from 'styled-components';
import { SkeletonLoaderDuration } from '../../modules';

export const StyledPromotionDetails = styled.div`
  .promo-detail-label {
    padding-top: ${toRem(40)};
  }

  background-color: ${baseVariables.color['alert10']} !important;
  border-radius: ${toRem(14)};
  box-sizing: border-box;
  box-shadow: 0 ${toRem(4)} ${toRem(20)} ${baseVariables.shadows.boxShadowTile};
  &__skeleton {
    &__desc {
      height: ${toRem(14)};
    }
    &__label {
      width: 70%;
      height: ${toRem(24)};
    }
    &:after {
      animation-duration: ${SkeletonLoaderDuration};
    }
  }
  &__promotion-detail {
    word-wrap: break-word;
    a {
      text-decoration: underline;
    }
  }
`;
