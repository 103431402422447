// Styles for ChinaConsentForm go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledDefaultConsentForm = styled.div<{ isOcj?: boolean }>`
  .privacy-consent {
    background-color: ${baseVariables.color.neutral20};
  }

  .kr-border-bottom {
    border-bottom: ${toRem(1)} solid ${baseVariables.color.neutral30};
  }
  .cn-border {
    border-top: ${toRem(1)} solid ${baseVariables.color.neutral30};
  }
  .paragraph-margin {
    p {
      margin: 0;
    }
  }
  .show-link {
    cursor: pointer;
  }
  label {
    margin: 0;
  }
  .create-account-consent {
    a {
      @media ${baseVariables.mediaQuery.md} {
        white-space: nowrap !important;
      }
    }
  }
`;
