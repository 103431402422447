// Styles for RegisteredPromotionInformation go here.
import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledOtpTimer = styled.div`
  .pointer-cursor {
    cursor: pointer;
  }

  .resend-code {
    p {
      margin-bottom: 0 !important;
    }
  }

  .validation-msg {
    color: ${baseVariables.color['alert50']} !important;
  }
`;
