// Styles for MissingStayRequestConfirmation go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledReportMissingStayRequestConfirmation = styled.div`
  .request-confirmation {
    border-radius: ${toRem(10)};

    &__line {
      color: ${baseVariables.color['neutral30']};
    }
  }
  .request-confirmation-link {
    .m-link-back-tertiary-button {
      &:before {
        position: static;
        transform: rotate(-180deg) translate(${toRem(-6)}, ${toRem(-2)});
      }
    }
  }
`;
