// Styles for TargetBanner go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledTargetBanner = styled.div`
  & .cobrand-banner {
    border-radius: ${toRem(14)};
  }
  .hide-for-target {
    display: none;
  }
  //overriding common component style
  .flexible-card {
    @media ${baseVariables.mediaQuery.md} {
      padding-right: ${toRem(32)} !important;
    }
    h2 {
      padding-bottom: ${toRem(8)} !important;
    }
  }
  .target-banner-image {
    img {
      @media ${baseVariables.mediaQuery.md} {
        max-height: ${toRem(80)} !important;
      }
    }
  }
`;
