import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const FooterStyle = styled.div`
  .footer-container {
    &__wrapper {
      @media ${baseVariables.mediaQuery.md} {
        gap: ${toRem(16)};
      }

      @media ${baseVariables.mediaQuery.lg} {
        gap: ${toRem(72)};
      }
    }

    &__border {
      border-right: ${toRem(1)} solid ${baseVariables.color['neutral30']};
    }
  }
`;

export const FooterMobileDropDownStyle = styled.div`
  .footermobile {
    border-color: ${baseVariables.color['neutral30']};
    border-width: 0 0 ${toRem(1)} 0;
    border-style: solid;

    &__data {
      width: 100%;
    }

    &__button {
      background-color: transparent;
    }
  }
`;

export const ItemGroupStyle = styled.div`
  .footer-box {
    @media ${baseVariables.mediaQuery.md} {
      height: auto;
    }

    &--header {
      @media ${baseVariables.mediaQuery.md} {
        width: 100%;
      }
    }
  }
`;
