import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { usePathname } from 'next/navigation';

import { EditableComponent } from '@adobe/aem-react-editable-components';

import { Heading, Link, Text, Types } from '@marriott/mi-ui-library';
import { EMAIL_DATA_KEY } from '../../modules';
import { useAccountPersistentStore } from '../../modules/store/accountPersistentStore';
import { useStore } from '../../modules/store/memberLevelStore';
import { replaceText } from '../../modules/utils/promotionHelper';
import { ForgotPasswordEmailSentProps } from './ForgotPasswordEmailSent.types';
import { StyledForgotPasswordEmailSent, StyledCustomMessage } from './ForgotPasswordEmailSent.styles';

export const ForgotPasswordEmailSent: FC<ForgotPasswordEmailSentProps> = (props: ForgotPasswordEmailSentProps) => {
  const pathName = usePathname();
  const isError = props?.model?.isErrorPage;
  const [emailData, setEmailData] = useState<string | null>('');
  const [redirectParam, setRedirectParam] = useState('');
  const { isForgotPasswordSuccessEaa, showCustomMessage } = useStore(state => state);
  const { setIsEAAEnrollFailed } = useAccountPersistentStore(state => state);

  useEffect(() => {
    if (!showCustomMessage) {
      const customerEmailData = sessionStorage?.getItem(EMAIL_DATA_KEY);
      setEmailData(customerEmailData);
    }
    isError && setIsEAAEnrollFailed?.(false);
    const urlParams = new URLSearchParams(window.location.search);
    setRedirectParam(urlParams.get('redirect_uri') || urlParams.get('redirectUri') || '');
  }, []);

  return (
    <div data-component-name="o-account-forgotpasswordemailsent" data-testid="forgotpasswordemailsent">
      {showCustomMessage || isError ? (
        <StyledCustomMessage className="container">
          <div className={clsx('mx-auto col-md-8 col-12', isError ? 'error-message px-0' : 'col-xl-5')}>
            <div className={clsx(isError && 'custom-error-wrapper', 'custom-message-wrapper')}>
              <Heading
                variation={Types.headingType.title}
                titleText={props?.model?.heading}
                fontSize={Types.size.small}
              />
              <div className={clsx('mb-5', isError ? 'mt-3' : 'mt-2')}>
                <Heading
                  titleText={props?.model?.description}
                  variation={Types.headingType.subtitle}
                  fontSize={Types.size.large}
                />
              </div>
              {isError && redirectParam && (
                <div className="mb-5">
                  <Link
                    text={props?.model?.goBackCtaLabel || ''}
                    linkClassName="m-link-tertiary-button-external t-font-m"
                    linkHref={redirectParam || ''}
                    target={'_blank'}
                  />
                </div>
              )}
              <Text
                copyText={replaceText(props?.model?.copyright || '', [String(moment().year())])}
                fontSize={Types.size.extraSmall}
                element={Types.tags.span}
              />
            </div>
          </div>
        </StyledCustomMessage>
      ) : (
        (props?.isAuthorMode || isForgotPasswordSuccessEaa || pathName?.includes('/emailPassword')) && (
          <StyledForgotPasswordEmailSent>
            <div className="container spacing-top-bottom">
              <Heading
                variation={Types.headingType.title}
                titleText={props?.model?.heading}
                customClass={'t-title-s'}
              />
              <div className="mt-4 mb-5">
                <Text
                  copyText={props?.model?.title}
                  fontSize={Types.size.large}
                  customClass={'t-subtitle-m'}
                  element={Types.tags.span}
                />
                <br />
                <div className="pb-5">
                  <Text
                    copyText={props?.model?.description?.replace(
                      '{0}',
                      emailData ?? sessionStorage?.getItem(EMAIL_DATA_KEY) ?? ''
                    )}
                    fontSize={Types.size.small}
                    customClass={'t-font-s'}
                    element={Types.tags.span}
                  />
                </div>
                {isForgotPasswordSuccessEaa && (
                  <div className="mt-4 mt-md-5">
                    <Text
                      copyText={props?.model?.copyright?.replace('{0}', String(moment().year()))}
                      fontSize={Types.size.extraSmall}
                      customClass={'t-font-xs'}
                      element={Types.tags.span}
                    />
                  </div>
                )}
              </div>
            </div>
          </StyledForgotPasswordEmailSent>
        )
      )}
    </div>
  );
};

export const ForgotPasswordEmailSentConfig = {
  emptyLabel: 'ForgotPasswordEmailSent',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/forgotpasswordemailsent`,
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ForgotPasswordEmailSentEditable = (props: any) => {
  return (
    <EditableComponent config={ForgotPasswordEmailSentConfig} {...props}>
      <ForgotPasswordEmailSent {...props} />
    </EditableComponent>
  );
};
