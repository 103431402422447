// The first character has to be "A" through "Z", "0" through "9".
// The remaining characters can have any of the following allowed characters "A" through "Z", "0" through "9","-", "'", ",", ".", " ".
export const stateValidationRegex = /^[a-zA-Z][a-zA-Z0-9-',. ]+$/;

// The first character must be "A" through "Z", or "0" through "9".
// The remaining characters can have any of the following allowed characters "A" through "Z","0" through "9", "-" ,"'", ".", " ", "#", ",", "/", or "\",
export const cityValidationRegex = /^[a-zA-Z][a-zA-Z0-9-',.  #\\/]+$/;

// the minimum is 5 characters, and the maximum is 10 characters.
export const lengthValidationRegex = /^.{5,10}$/;

// Positions 1, 3, and 6 are alphabetic (A-Z), AND positions 2, 5, 7 are numeric (0-9), AND position 4 is a space.
export const canadaZipcodeWithSpaceRegex = /^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$/;

// Or OR positions 1, 3, 5 are alphabetic (A-Z), AND positions 2, 4, 6 are numeric (0-9).
export const canadaZipcodeRegex = /^[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]$/;

// Positions 1-9 are numeric, AND position 10 is a space.
// OR positions 1-5 are numeric, AND positions 6-10 are a space.
// OR positions 1-5 are numeric, AND position 6 is a hyphen, AND positions 7-10 are numeric.
export const usZipcodeRegex = /^\d{5}(-\d{4})?$/;

// have characters followed by @ then again characters followed by dot(.) then again characters
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//matches all non-digit characters
export const nonDigitRegex = /\D/;

//matches all digits are zero's or not
export const allZeroDigitsRegex = /^0+$/;

//sign in email validation, matches digits, alphabets and some special chars
export const signEmailRegex = /^[_0-9A-Za-z-.@ ]+$/;

//sign in phone validation, matches digits and  +
export const signMobileRegex = /^[0-9+ ]+$/;

// It accepts only alphabet and digits
export const alphaNumericRegex = '^[a-zA-Z0-9]+$';

//It accepts only alphabet
export const alphabetRegex = /^[A-Za-z]+$/;

//it accepts only letter, space and hyphens.
export const nameFieldRegex = /^\s*[a-zA-Z]+(?:[-' ][a-zA-Z]+)*\s*$/;

//it accepts only alphabets,numbers and space
export const nonCanadaUsZipcodeRegex = /^[a-zA-Z0-9\s]+$/;

//it accepts only alphabets,numbers and space
export const chineseCharRegex = /^[\u4E00-\u9FA5\s-]+$/;

//it accepts only alphabets,pinyin,hyphen and space
export const englishPinyinRegex = /^[a-zA-Z\u0101-\u01DC\s-]+$/;

// Check if the URL starts with http:// or https://
export const httpRegex = /^(http:\/\/|https:\/\/)/;
