import React, { FC, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { CheckBox, Link, RichText, Text, Button, Types } from '@marriott/mi-ui-library';
import { PageContext } from '../../modules';
import {
  ENROLLMENT_CHINA_CONSENT_ENUM,
  ENROLLMENT_KOREAN_CONSENT_ENUM,
  ENROLLMENT_US_CONSENT_ENUM,
  ENROLLMENT_VIETNAM_CONSENT_ENUM,
  ENROLLMENT_CONSENT_TYPES,
  IS_DEFAULT_UNCHECKED,
  constants,
  mi_consent,
  tp_consent,
  COUNTRY_SET_CN_VN_KR,
  COUNTRY_SET_CN_VN_JP,
  KOREAN_MARKETING_CODES,
  KOREAN_PERSONAL_CODES,
  ONE_CLICK_JOIN_MODAL_TYPE,
  MARRIOTT_URL,
  MARRIOTT_URL_CHINA,
} from '../../modules/utils/constants/constants';
import { addClassToBlankTargetLinks, checkChinaLocale } from '../../modules/utils';
import { DefaultConsentFormProps, Checkbox } from './DefaultConsentForm.types';
import { StyledDefaultConsentForm } from './DefaultConsentForm.styles';
import { useCreateAccountStore } from '../../modules/store/createAccountStore';

export const DefaultConsentForm: FC<DefaultConsentFormProps> = ({
  model,
  countryCode,
  showmore,
  showless,
  isOcj,
  errorMessagePersonal,
  errorMessagePromotions,
  modalType = '',
  isEnrollEAA,
}) => {
  const {
    checkedItems,
    setCheckedItems,
    initialCheckedItems,
    setIntitalCheckedItems,
    setJoinNowEnabled,
    consents,
    setConsents,
    setConsentErrors,
  } = useCreateAccountStore();
  const [showContent, setShowContent] = useState<Record<string, boolean>>({});
  const isCheckedIn = modalType === ONE_CLICK_JOIN_MODAL_TYPE?.CHECKED_IN;
  const pageContext = useContext(PageContext);
  const relativeURLPrefix = checkChinaLocale(pageContext?.currentLocale || '') ? MARRIOTT_URL_CHINA : MARRIOTT_URL;
  const toggleContent = (key: string) => {
    const newShowContent = { ...showContent };
    newShowContent[key] = !newShowContent[key] || false;

    setShowContent(newShowContent);
  };

  // getdefaultcheckedvalue
  const getDefaultCheckboxValue = (countryCode: string, ele: Checkbox): boolean => {
    const isDefaultUnchecked = IS_DEFAULT_UNCHECKED?.includes(countryCode);
    if (isDefaultUnchecked) {
      return false;
    }
    if (countryCode === constants.MEXICO_COUNTRY_CODE) {
      return ele?.consentLinkType === mi_consent;
    }
    return ele?.consentLinkType === mi_consent || ele?.consentLinkType === tp_consent;
  };

  // handle logic on page load
  useEffect(() => {
    if (model?.consentFormList) {
      const updatedCheckedItems: { checkboxId: string; checked: boolean; key: string }[] =
        model.consentFormList.flatMap((cform, cformindex) => {
          return (
            (cform?.checkboxes !== null &&
              cform?.checkboxes.map((checkbox, index) => {
                const isChecked = getDefaultCheckboxValue(countryCode || '', checkbox);
                return {
                  checkboxId: `${cformindex}-${index}`,
                  checked: isChecked,
                  key: checkbox.key,
                };
              })) ||
            []
          );
        });
      setCheckedItems(updatedCheckedItems);
      setIntitalCheckedItems(updatedCheckedItems);
      setConsents([
        { type: ENROLLMENT_CONSENT_TYPES.CORE_REQUIRED_CONSENT, optin: false, ctype: 'userConsents' },
        { type: ENROLLMENT_CONSENT_TYPES.PERSONALIZED_MARKETING_CONSENT, optin: false, ctype: 'userConsents' },
        { type: ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER, optin: false, ctype: 'communicationPreferences' },
        { type: ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER, optin: false, ctype: 'communicationPreferences' },
      ]);
    }
  }, [countryCode]);

  useEffect(() => {
    let updatedConsents = [...consents];
    const checkedConsents = {
      cnprcChecked: false,
      cncbcChecked: false,
      vnprcChecked: false,
      vncbcChecked: false,
      krprcChecked: false,
      krcbcChecked: false,
      krmrktChecked: false,
      krtrnsChecked: false,
      krtrns_1Checked: false,
      krhtldsChecked: false,
      krprgrmChecked: false,
      krprgrm_1Checked: false,
      krprtnrChecked: false,
    };

    // Variables to track whether CORE_REQUIRED_CONSENT should be updated
    let updateCoreRequiredConsent = false;
    // Variables to track whether CORE_REQUIRED_CONSENT should be updated
    let updatePersonalMarketingConsent = false;
    let koreanMarketingConsentChecked = false;

    checkedItems.forEach(currentCheckedItem => {
      const key = currentCheckedItem?.key;
      switch (key) {
        case ENROLLMENT_CHINA_CONSENT_ENUM.CNPRC:
          checkedConsents.cnprcChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_CHINA_CONSENT_ENUM.CNCBC:
          checkedConsents.cncbcChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_VIETNAM_CONSENT_ENUM.VNPRC:
          checkedConsents.vnprcChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_VIETNAM_CONSENT_ENUM.VNCBC:
          checkedConsents.vncbcChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.KRPRC:
          checkedConsents.krprcChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.KRCBC:
          checkedConsents.krcbcChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.MRKT:
          checkedConsents.krmrktChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.TRNS:
          checkedConsents.krtrnsChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.TRNS_1:
          checkedConsents.krtrns_1Checked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.HTLDS:
          checkedConsents.krhtldsChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.PRGRM:
          checkedConsents.krprgrmChecked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.PRGRM_1:
          checkedConsents.krprgrm_1Checked = currentCheckedItem.checked;
          break;
        case ENROLLMENT_KOREAN_CONSENT_ENUM.PRTNR:
          checkedConsents.krprtnrChecked = currentCheckedItem.checked;
          break;
        default:
          break;
      }
      if (currentCheckedItem.checked) {
        // Update the corresponding consent based on the checked item
        updatedConsents = updatedConsents.map(consent => {
          if (
            (key === ENROLLMENT_US_CONSENT_ENUM.SP &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER) ||
            (key === ENROLLMENT_US_CONSENT_ENUM.SO && consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER) ||
            (key === ENROLLMENT_CHINA_CONSENT_ENUM.SOCN &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER) ||
            (key === ENROLLMENT_CHINA_CONSENT_ENUM.SPCN &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER) ||
            (key === ENROLLMENT_VIETNAM_CONSENT_ENUM.SOVN &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER) ||
            (key === ENROLLMENT_VIETNAM_CONSENT_ENUM.SPVN &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER)
          ) {
            return { ...consent, optin: true };
          } else if (
            checkedConsents.cnprcChecked &&
            checkedConsents.cncbcChecked &&
            consent.type === ENROLLMENT_CONSENT_TYPES.CORE_REQUIRED_CONSENT
          ) {
            updateCoreRequiredConsent = true;
            return { ...consent, optin: true };
          } else if (
            checkedConsents.vnprcChecked &&
            checkedConsents.vncbcChecked &&
            consent.type === ENROLLMENT_CONSENT_TYPES.CORE_REQUIRED_CONSENT
          ) {
            updateCoreRequiredConsent = true;
            return { ...consent, optin: true };
          } else if (
            checkedConsents.krprcChecked &&
            checkedConsents.krcbcChecked &&
            consent.type === ENROLLMENT_CONSENT_TYPES.CORE_REQUIRED_CONSENT
          ) {
            updateCoreRequiredConsent = true;
            return { ...consent, optin: true };
          } else if (
            checkedConsents.krmrktChecked &&
            checkedConsents.krtrnsChecked &&
            checkedConsents.krtrns_1Checked &&
            consent.type === ENROLLMENT_CONSENT_TYPES.PERSONALIZED_MARKETING_CONSENT
          ) {
            updatePersonalMarketingConsent = true;
            return { ...consent, optin: true };
          } else if (
            checkedConsents.krhtldsChecked &&
            checkedConsents.krprgrmChecked &&
            checkedConsents.krprgrm_1Checked &&
            checkedConsents.krprtnrChecked &&
            (consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER ||
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER)
          ) {
            koreanMarketingConsentChecked = true;
            return { ...consent, optin: true };
          } else {
            return consent;
          }
        });
      } else if (!currentCheckedItem.checked) {
        updatedConsents = updatedConsents.map(consent => {
          if (
            (key === ENROLLMENT_US_CONSENT_ENUM.SP &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER) ||
            (key === ENROLLMENT_US_CONSENT_ENUM.SO && consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER) ||
            (key === ENROLLMENT_CHINA_CONSENT_ENUM.SOCN &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER) ||
            (key === ENROLLMENT_CHINA_CONSENT_ENUM.SPCN &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER) ||
            (key === ENROLLMENT_VIETNAM_CONSENT_ENUM.SOVN &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER) ||
            (key === ENROLLMENT_VIETNAM_CONSENT_ENUM.SPVN &&
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER)
          ) {
            return { ...consent, optin: false };
          } else if (
            (key === ENROLLMENT_CHINA_CONSENT_ENUM.CNCBC || key === ENROLLMENT_CHINA_CONSENT_ENUM.CNPRC) &&
            consent.type === ENROLLMENT_CONSENT_TYPES.CORE_REQUIRED_CONSENT
          ) {
            updateCoreRequiredConsent = false;
            return { ...consent, optin: false };
          } else if (
            (key === ENROLLMENT_VIETNAM_CONSENT_ENUM.VNCBC || key === ENROLLMENT_VIETNAM_CONSENT_ENUM.VNPRC) &&
            consent.type === ENROLLMENT_CONSENT_TYPES.CORE_REQUIRED_CONSENT
          ) {
            updateCoreRequiredConsent = false;
            return { ...consent, optin: false };
          } else if (
            (key === ENROLLMENT_KOREAN_CONSENT_ENUM.KRPRC || key === ENROLLMENT_KOREAN_CONSENT_ENUM.KRCBC) &&
            consent.type === ENROLLMENT_CONSENT_TYPES.CORE_REQUIRED_CONSENT
          ) {
            updateCoreRequiredConsent = false;
            return { ...consent, optin: false };
          } else if (
            (key === ENROLLMENT_KOREAN_CONSENT_ENUM.MRKT ||
              key === ENROLLMENT_KOREAN_CONSENT_ENUM.TRNS ||
              key === ENROLLMENT_KOREAN_CONSENT_ENUM.TRNS_1) &&
            consent.type === ENROLLMENT_CONSENT_TYPES.PERSONALIZED_MARKETING_CONSENT
          ) {
            const personalConsents = checkedItems.filter(consent => KOREAN_PERSONAL_CODES.includes(consent.key ?? ''));
            const consentUnchecked = personalConsents.some(consent => !consent.checked);
            updatePersonalMarketingConsent = !consentUnchecked;
            return { ...consent, optin: !consentUnchecked };
          } else if (
            (key === ENROLLMENT_KOREAN_CONSENT_ENUM.HTLDS ||
              key === ENROLLMENT_KOREAN_CONSENT_ENUM.PRGRM ||
              key === ENROLLMENT_KOREAN_CONSENT_ENUM.PRGRM_1 ||
              key === ENROLLMENT_KOREAN_CONSENT_ENUM.PRTNR) &&
            (consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER ||
              consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER)
          ) {
            const marketingConsents = checkedItems.filter(consent =>
              KOREAN_MARKETING_CODES.slice(1).includes(consent.key ?? '')
            );
            const consentUnchecked = marketingConsents.some(consent => !consent.checked);
            koreanMarketingConsentChecked = !consentUnchecked;
            return { ...consent, optin: !consentUnchecked };
          } else {
            return consent;
          }
        });
      }
    });

    // Update the CORE_REQUIRED_CONSENT if necessary
    if (updateCoreRequiredConsent) {
      updatedConsents = updatedConsents.map(consent => {
        if (consent.type === ENROLLMENT_CONSENT_TYPES.CORE_REQUIRED_CONSENT) {
          return { ...consent, optin: true };
        } else {
          return consent;
        }
      });
    }
    // Update the PERSONALIZED_MARKETING_CONSENT if necessary
    if (updatePersonalMarketingConsent) {
      updatedConsents = updatedConsents.map(consent => {
        if (consent.type === ENROLLMENT_CONSENT_TYPES.PERSONALIZED_MARKETING_CONSENT) {
          return { ...consent, optin: true };
        } else {
          return consent;
        }
      });
    }

    if (koreanMarketingConsentChecked) {
      updatedConsents = updatedConsents.map(consent => {
        if (
          consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_OFFER ||
          consent.type === ENROLLMENT_CONSENT_TYPES.SUBSCRIPTION_3RD_OFFER
        ) {
          return { ...consent, optin: true };
        } else {
          return consent;
        }
      });
    }

    if (countryCode === constants.KOREA_COUNTRY_CODE) {
      const personalConsents = checkedItems.filter(consent => KOREAN_PERSONAL_CODES.includes(consent.key ?? ''));
      const personalConsentError = !personalConsents.every(consent => consent.checked === personalConsents[0].checked);
      const marketingConsents = checkedItems.filter(consent =>
        KOREAN_MARKETING_CODES.slice(1).includes(consent.key ?? '')
      );
      const marketingConsentError = !marketingConsents.every(
        consent => consent.checked === marketingConsents[0].checked
      );
      const koreanConsentErrors = {
        ...(personalConsentError && { errorMessagePromotions: errorMessagePromotions }),
        ...(marketingConsentError && { errorMessagePersonal: errorMessagePersonal }),
      };
      setConsentErrors(koreanConsentErrors);
    }

    // Update the consents array once after all updates
    setConsents(updatedConsents);
  }, [checkedItems, initialCheckedItems]);

  const updateAllCheckBoxWithSameValue = (val: boolean) => {
    const updatedCheckedItems = checkedItems.map(item => ({
      ...item,
      checked: val,
    }));
    setCheckedItems(updatedCheckedItems);
  };

  const updateCheckBoxState = (checkboxId: string, checked: boolean) => {
    const updatedCheckedItems: { key?: string; checkboxId: string; checked: boolean }[] = checkedItems.map(item => {
      if (
        item.checkboxId === '0-0' &&
        !checked &&
        checkboxId !== '0-0' &&
        item.checked &&
        countryCode === constants.KOREA_COUNTRY_CODE
      ) {
        return { ...item, checked: !item.checked };
      }
      if (item.checkboxId === checkboxId) {
        return { ...item, checked: checked };
      }
      return item;
    });
    setCheckedItems(updatedCheckedItems);
    return updatedCheckedItems;
  };

  const handleCheckboxChange = (checkboxId: string, checked: boolean) => {
    const currentCheckedItem = checkedItems.find(checkbox => checkbox.checkboxId === checkboxId);
    if (countryCode === constants.KOREA_COUNTRY_CODE) {
      if (currentCheckedItem?.checkboxId === '0-0') {
        updateAllCheckBoxWithSameValue(checked);
      } else {
        const updatedCheckedItems = updateCheckBoxState(checkboxId, checked);
        const isAllItemsAreChecked = updatedCheckedItems.filter(checkbox => !checkbox.checked);
        if (
          isAllItemsAreChecked?.length === 1 &&
          isAllItemsAreChecked?.[0]?.checkboxId === '0-0' &&
          !isAllItemsAreChecked?.[0]?.checked &&
          checked
        ) {
          updateAllCheckBoxWithSameValue(true);
        }
      }
    } else {
      updateCheckBoxState(checkboxId, checked);
    }
  };

  // handle logics on checkbox change
  useEffect(() => {
    const updateConsent = () => {
      let joinNowEnabled = true;

      if (countryCode && COUNTRY_SET_CN_VN_KR.includes(countryCode)) {
        joinNowEnabled =
          checkedItems.some(item => item.key === `${countryCode}CBC` && item.checked) &&
          checkedItems.some(item => item.key === `${countryCode}PRC` && item.checked);
      }
      setJoinNowEnabled(joinNowEnabled);
    };
    updateConsent();
  }, [checkedItems, initialCheckedItems, countryCode]);

  return (
    <StyledDefaultConsentForm
      data-component-name="m-account-DefaultConsentForm"
      data-testid="account-DefaultConsentForm"
      isOcj={isOcj}
    >
      {model?.consentFormList?.map((cform, cformindex) => {
        return (
          <div
            key={cformindex}
            className={clsx(
              countryCode && countryCode === constants.KOREA_COUNTRY_CODE && cformindex === 0 && 'privacy-consent'
            )}
          >
            <div className={clsx(!isOcj && 'mx-md-auto px-sm-0')}>
              <div className={clsx(!isCheckedIn && (isOcj ? 'px-4 px-md-5' : 'create-account-consent'))}>
                <div
                  className={clsx(
                    countryCode && COUNTRY_SET_CN_VN_KR.includes(countryCode) ? 'py-5' : 'py-4',
                    isOcj &&
                      countryCode === constants.KOREA_COUNTRY_CODE &&
                      !(cformindex === 0) &&
                      !(cformindex === 3) &&
                      'kr-border-bottom',
                    !isOcj && countryCode === constants.KOREA_COUNTRY_CODE && !(cformindex === 0) && 'kr-border-bottom',
                    countryCode && COUNTRY_SET_CN_VN_JP.includes(countryCode) && cformindex === 0 && 'cn-border',
                    isOcj &&
                      countryCode &&
                      COUNTRY_SET_CN_VN_JP.includes(countryCode) &&
                      !(cformindex === 1) &&
                      'kr-border-bottom',
                    !isOcj && countryCode && COUNTRY_SET_CN_VN_JP.includes(countryCode) && 'kr-border-bottom',
                    isCheckedIn &&
                      ((countryCode === constants.KOREA_COUNTRY_CODE && cformindex !== 0) ||
                      countryCode !== constants.KOREA_COUNTRY_CODE
                        ? 'pr-4 pr-md-4'
                        : 'px-4 px-md-5')
                  )}
                >
                  <div className={clsx(cform?.consentTitle || cform?.consentSubTitle ? 'mb-md-5' : '')}>
                    {cform?.consentTitle && (
                      <RichText
                        customClass="t-subtitle-l"
                        text={addClassToBlankTargetLinks(
                          cform?.consentTitle,
                          isOcj || isEnrollEAA ? '_parent' : '_blank',
                          isEnrollEAA ? relativeURLPrefix : ''
                        )}
                        componentId={`parentTitle-${modalType}-${cformindex}`}
                      />
                    )}
                    {cform?.consentSubTitle && (
                      <RichText
                        customClass={clsx(`t-font-m ${cform?.consentTitle && 'mt-2'}`)}
                        text={addClassToBlankTargetLinks(
                          cform?.consentSubTitle,
                          isOcj || isEnrollEAA ? '_parent' : '_blank',
                          isEnrollEAA ? relativeURLPrefix : ''
                        )}
                        componentId={`parentSubTitle-${modalType}-${cformindex}`}
                      />
                    )}
                  </div>
                  {cform?.checkboxes?.length &&
                    cform?.checkboxes?.map((checkbox, cboxindex) => (
                      <div
                        key={cboxindex}
                        className={clsx(
                          'd-flex row m-0',
                          cboxindex !== 0 ? 'pt-3 mt-1' : '',
                          showContent[`${cformindex}-${cboxindex}`]
                            ? 'flex-wrap'
                            : 'flex-nowrap justify-content-between'
                        )}
                      >
                        <div
                          className={clsx(
                            countryCode === constants.KOREA_COUNTRY_CODE && `${cformindex}-${cboxindex}` === '0-0'
                              ? 'col-12 p-0'
                              : countryCode === constants.KOREA_COUNTRY_CODE
                              ? 'p-0 col-9 col-md-10'
                              : 'd-flex justify-content-center'
                          )}
                        >
                          <CheckBox
                            checkboxId={
                              cform?.consentSubTitle
                                ? `subtitle-${modalType}-${cformindex}-${cboxindex}`
                                : `title-${modalType}-${cformindex}-${cboxindex}`
                            }
                            checkboxName={`${cformindex}-${cboxindex}`}
                            className="mb-0  consent-checkbox"
                            children={
                              <RichText
                                customClass="t-font-s paragraph-margin m-0 p-0"
                                text={addClassToBlankTargetLinks(
                                  checkbox?.consentCheckBoxDescription,
                                  isOcj || isEnrollEAA ? '_parent' : '_blank',
                                  isEnrollEAA ? relativeURLPrefix : ''
                                )}
                                componentId={`checkboxDescription-${modalType}-${cformindex}-${cboxindex}`}
                              />
                            }
                            checked={
                              checkedItems.find(item => item.checkboxId === `${cformindex}-${cboxindex}`)?.checked ||
                              false
                            }
                            onChange={(even: React.ChangeEvent<HTMLInputElement>) =>
                              handleCheckboxChange(`${cformindex}-${cboxindex}`, even.target.checked)
                            }
                            key={cboxindex}
                            data-testid={`${cformindex}-${cboxindex}`}
                            ariaLabel={`${cformindex}-${cboxindex}`}
                          />
                        </div>
                        {countryCode === constants.KOREA_COUNTRY_CODE &&
                          !isOcj &&
                          checkbox?.consentTermsLabel !== '' && (
                            <Link
                              text={checkbox?.consentTermsLabel}
                              linkClassName={clsx(`t-font-s align-self-start m-link p-0`)}
                              linkHref={
                                isEnrollEAA
                                  ? `${relativeURLPrefix}${checkbox?.consentTermsLink}`
                                  : checkbox?.consentTermsLink || ''
                              }
                              target={isEnrollEAA ? '_blank' : '_parent'}
                              linkType="internal"
                            />
                          )}

                        {isOcj && checkbox?.consentTermsLabel && (
                          <div
                            className={clsx(showContent[`${cformindex}-${cboxindex}`] ? 'col-12 ml-4' : '')}
                            tabIndex={0}
                            onKeyDown={event => {
                              if (event.key === 'Enter' || event.key === ' ') {
                                toggleContent(`${cformindex}-${cboxindex}`);
                              }
                            }}
                            role="button"
                          >
                            {showContent[`${cformindex}-${cboxindex}`] && (
                              <RichText
                                customClass="d-inline t-font-m"
                                text={checkbox?.consentsDescription}
                                componentId={`consentDescription-${modalType}-${cformindex}-${cboxindex}`}
                              />
                            )}
                            <Button
                              className={'ml-1 m-link p-0 show-link text-nowrap'}
                              isLink={true}
                              type={Types.ButtonTypeVariation.Button}
                              buttonCopy={showContent[`${cformindex}-${cboxindex}`] ? showless : showmore}
                              callback={() => toggleContent(`${cformindex}-${cboxindex}`)}
                              ariaRole="button"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </StyledDefaultConsentForm>
  );
};
