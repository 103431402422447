// Styles for SetPassword go here.

import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledSetPassword = styled.div`
  .set-password-container {
    &__form {
      &--layout {
        .green-check {
          color: ${baseVariables.color['merch20']};
        }
        .red-clear {
          color: ${baseVariables.color['alert50']};
        }
        .submit-btn {
          width: 100%;
          @media ${baseVariables.mediaQuery.sm} {
            width: ${toRem(128)};
          }
        }
        .cancel-button {
          width: 100%;
          border-bottom: ${toRem(1)} solid ${baseVariables.color.base10};
          @media ${baseVariables.mediaQuery.sm} {
            width: ${toRem(128)};
          }
        }
      }
    }
  }
  .form-disabled * {
    color: ${baseVariables.color['neutral30']};
  }
  .m-input-field {
    input {
      caret-color: ${baseVariables.color['neutral40']};
      &::-ms-reveal {
        display: none;
      }
    }
    &.is-error {
      &:focus-within {
        label {
          color: ${baseVariables.color.alert50};
        }
      }
      input:focus {
        box-shadow: none;
        outline: 0;
      }

      .country-code-dropdown {
        border-color: ${baseVariables.color.alert50} !important;
      }
    }
    &.disabled {
      label {
        color: ${baseVariables.color.alert20};
        z-index: 1;
      }
      input {
        border-color: ${baseVariables.color.alert20};
        outline: 0;
        color: ${baseVariables.color.neutral40} !important;
        &:focus {
          outline: 0;
          border-color: ${baseVariables.color.alert20};
          box-shadow: none;
        }
      }
    }
  }

  .help-text {
    &-decoration {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .pointer-cursor {
    cursor: pointer;
  }
  .disabled-text {
    pointer-events: none;
    color: ${baseVariables.color.alert20};
  }
  p {
    margin-bottom: 0 !important;
  }
  .create-account-submit-btn {
    width: 100%;
    @media ${baseVariables.mediaQuery.sm} {
      width: auto;
      min-width: ${toRem(128)}; // UX team has asked to set the width of button as per VD
    }
  }

  .phoen-help-text {
    width: 100%;

    @media ${baseVariables.mediaQuery.md} {
      width: 50%;
    }
  }
  .validation-msg {
    color: ${baseVariables.color['alert50']} !important;
  }
  .email-field {
    &.m-input-field {
      &.is-disabled {
        label {
          z-index: 1;
        }
      }
      caret-color: inherit; // This is to override transparent caret color
    }
  }
`;
