/* eslint-disable @typescript-eslint/no-explicit-any */
// This file manages exports from the base atom, molecule, or organism.
// Use named rather than default exports.
import { EditableComponent } from '@adobe/aem-react-editable-components';
import dynamic from 'next/dynamic';

const CancelReservation = dynamic(() => import('./CancelReservation').then(mod => mod.CancelReservation));
const CancelReservationV2 = dynamic(() => import('./CancelReservationV2').then(mod => mod.CancelReservationV2));

export const CancelReservationConfig = {
  emptyLabel: 'cancelreservation',
  isEmpty: false,
  resourceType: `mi-aem-account/components/content/cancelreservation`,
};

export const CancelReservationEditable = (props: any) => {
  return (
    <EditableComponent config={CancelReservationConfig} {...props}>
      {props?.isDTT ? <CancelReservationV2 {...props} /> : <CancelReservation {...props} />}
    </EditableComponent>
  );
};
