// Styles for OffersCard go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const OverviewOffersStyles = styled.div`
  .card-layered {
    picture img {
      height: ${toRem(332)};
      width: ${toRem(332)};
      @media ${baseVariables.mediaQuery.sm} {
        height: ${toRem(342)};
        width: ${toRem(342)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        height: ${toRem(269)};
        width: ${toRem(268)};
      }
    }
    &__content {
      background: ${baseVariables.gradient['baseGradient05']};
      .card-texts {
      }
    }
  }
  .list-unstyled {
    list-style-type: none;
  }
  .glide__slides,
  .glide__track {
    overflow: hidden;
    .chevronContainer {
      margin: 0 auto;
      @media ${baseVariables.mediaQuery.md} {
        margin: 0;
      }
    }
  }
`;
