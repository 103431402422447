import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledSignOut = styled.div`
  .space-bottom {
    padding-bottom: ${toRem(120)};
  }
  .spacing-top-bottom {
    margin-bottom: ${toRem(104)};
    @media ${baseVariables.mediaQuery.md} {
      margin-bottom: ${toRem(120)};
    }
  }
  //overriding margin of rte label authored from aem
  .paragraphh {
    p {
      margin-bottom: ${toRem(8)};
    }
  }
`;
